import { DefaultLinkModel } from '@projectstorm/react-diagrams';

export class CustomLinkModel extends DefaultLinkModel {
    constructor(options = {}) {
        super({
            ...options,
            type: 'custom-link', // Use your custom type
        });
    }
}
