import * as React from "react";
import { ReorderColsNodeModel } from "./ReorderColsNodeModel";
import {
  DiagramEngine,
  PortModelAlignment,
  PortWidget,
} from "@projectstorm/react-diagrams";
import { SparkyELTPortModel } from "../../port/SparkyELTPortModel";
import { S } from "../../port/SimplePortWidget";
import { NodeTypeEnum } from "../NodeTypeEnum";
import DefaultNodeDecoration, { getPrimaryColorByNodeType } from "../DefaultNodeDecoration";

export interface ReorderColsNodeWidgetProps {
  node: ReorderColsNodeModel;
  engine: DiagramEngine;
  width: number;
  height: number;
}

export class ReorderColsNodeWidget extends React.Component<ReorderColsNodeWidgetProps> {
  render() {
    return (
      <div
        className={"diamond-node"}
        style={{
          position: "relative",
          width: this.props.width,
          height: this.props.height,
        }}
      >
        <svg
          width="304"
          height="179"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          overflow="hidden"
        >
          <defs>
            <filter
              id="fx0-nodeReOrderCols"
              x="-10%"
              y="-10%"
              width="120%"
              height="120%"
              filterUnits="userSpaceOnUse"
              primitiveUnits="userSpaceOnUse"
            >
              <feComponentTransfer colorInterpolationFilters="sRGB">
                <feFuncR type="discrete" tableValues="0 0" />
                <feFuncG type="discrete" tableValues="0 0" />
                <feFuncB type="discrete" tableValues="0 0" />
                <feFuncA type="linear" slope="0.4" intercept="0" />
              </feComponentTransfer>
              <feGaussianBlur stdDeviation="1.77778 1.77778" />
            </filter>
            <filter
              id="fx1-nodeReOrderCols"
              x="-10%"
              y="-10%"
              width="120%"
              height="120%"
              filterUnits="userSpaceOnUse"
              primitiveUnits="userSpaceOnUse"
            >
              <feComponentTransfer colorInterpolationFilters="sRGB">
                <feFuncR type="discrete" tableValues="0 0" />
                <feFuncG type="discrete" tableValues="0 0" />
                <feFuncB type="discrete" tableValues="0 0" />
                <feFuncA type="linear" slope="0.4" intercept="0" />
              </feComponentTransfer>
              <feGaussianBlur stdDeviation="1.77778 1.77778" />
            </filter>
            <filter
              id="fx2-nodeReOrderCols"
              x="-10%"
              y="-10%"
              width="120%"
              height="120%"
              filterUnits="userSpaceOnUse"
              primitiveUnits="userSpaceOnUse"
            >
              <feComponentTransfer colorInterpolationFilters="sRGB">
                <feFuncR type="discrete" tableValues="0 0" />
                <feFuncG type="discrete" tableValues="0 0" />
                <feFuncB type="discrete" tableValues="0 0" />
                <feFuncA type="linear" slope="0.4" intercept="0" />
              </feComponentTransfer>
              <feGaussianBlur stdDeviation="1.77778 1.77778" />
            </filter>
            <clipPath id="clip3-nodeReOrderCols">
              <rect x="57" y="273" width="304" height="179" />
            </clipPath>
            <clipPath id="clip4-nodeReOrderCols">
              <path
                d="M90.4067 281C75.8227 281 64 292.79 64 307.334L64 412.666C64 427.21 75.8227 439 90.4067 439L260 439 260 281ZM57 273 361 273 361 452 57 452Z"
                fillRule="evenodd"
                clipRule="evenodd"
              />
            </clipPath>
            <clipPath id="clip5-nodeReOrderCols">
              <path
                d="M271 281 271 439 296 439 296 281ZM57 273 361 273 361 452 57 452Z"
                fillRule="evenodd"
                clipRule="evenodd"
              />
            </clipPath>
            <clipPath id="clip6-nodeReOrderCols">
              <path
                d="M306 281 306 439 321.135 439C335.972 439 348 427.21 348 412.666L348 307.334C348 292.79 335.972 281 321.135 281ZM57 273 361 273 361 452 57 452Z"
                fillRule="evenodd"
                clipRule="evenodd"
              />
            </clipPath>
          </defs>
          <g
            clipPath="url(#clip3-nodeReOrderCols)"
            transform="translate(-57 -273)"
          >
            <g clipPath="url(#clip4-nodeReOrderCols)">
              <g
                filter="url(#fx0-nodeReOrderCols)"
                transform="translate(56 273)"
              >
                <g>
                  <path
                    d="M37.2352 10.8284 206.828 10.8284 206.828 168.828 37.2352 168.828C22.6511 168.828 10.8284 157.038 10.8284 142.495L10.8284 37.1623C10.8284 22.6185 22.6511 10.8284 37.2352 10.8284Z"
                    stroke="#FFFFFF"
                    strokeWidth="8"
                    strokeMiterlimit="8"
                    fill={getPrimaryColorByNodeType(NodeTypeEnum.NODE_REORDER_COL)}
                    fillRule="evenodd"
                  />
                </g>
              </g>
            </g>
            <path
              d="M90.4067 281 260 281 260 439 90.4067 439C75.8227 439 64 427.21 64 412.666L64 307.334C64 292.79 75.8227 281 90.4067 281Z"
              stroke="#FFFFFF"
              strokeWidth="8"
              strokeMiterlimit="8"
              fill={getPrimaryColorByNodeType(NodeTypeEnum.NODE_REORDER_COL)}
              fillRule="evenodd"
            />
            <g clipPath="url(#clip5-nodeReOrderCols)">
              <g
                filter="url(#fx1-nodeReOrderCols)"
                transform="translate(263 273)"
              >
                <g>
                  <rect
                    x="10.8284"
                    y="10.8284"
                    width="25"
                    height="158"
                    stroke="#FFFFFF"
                    strokeWidth="8"
                    strokeMiterlimit="8"
                    fill={getPrimaryColorByNodeType(NodeTypeEnum.NODE_REORDER_COL)}
                  />
                </g>
              </g>
            </g>
            <rect
              x="271"
              y="281"
              width="25"
              height="158"
              stroke="#FFFFFF"
              strokeWidth="8"
              strokeMiterlimit="8"
              fill={getPrimaryColorByNodeType(NodeTypeEnum.NODE_REORDER_COL)}
            />
            <g clipPath="url(#clip6-nodeReOrderCols)">
              <g
                filter="url(#fx2-nodeReOrderCols)"
                transform="translate(298 273)"
              >
                <g>
                  <path
                    d="M42 0 26.8647 0C12.0278 0 0 11.7901 0 26.3338L0 131.666C0 146.21 12.0278 158 26.8647 158L42 158Z"
                    stroke="#FFFFFF"
                    strokeWidth="8"
                    strokeMiterlimit="8"
                    fill={getPrimaryColorByNodeType(NodeTypeEnum.NODE_REORDER_COL)}
                    fillRule="evenodd"
                    transform="matrix(-1 0 0 1 52.8284 10.8284)"
                  />
                </g>
              </g>
            </g>
            <path
              d="M42 0 26.8647 0C12.0278 0 0 11.7901 0 26.3338L0 131.666C0 146.21 12.0278 158 26.8647 158L42 158Z"
              stroke="#FFFFFF"
              strokeWidth="8"
              strokeMiterlimit="8"
              fill={getPrimaryColorByNodeType(NodeTypeEnum.NODE_REORDER_COL)}
              fillRule="evenodd"
              transform="matrix(-1 0 0 1 348 281)"
            />
            <path d="M257.785 304.792 260.62 300.603 264.65 296.91 269.603 293.868 275.3 291.519 281.571 289.909 288.245 289.089 295.153 289.115 302.118 290.045 307.54 291.465 312.432 293.399 316.737 295.793 320.453 298.646 322.162 301.201 320.5 302.313 318.882 299.894 319.105 300.132 315.578 297.424 315.701 297.505 311.518 295.178 311.636 295.234 306.861 293.348 306.975 293.385 301.671 291.996 301.792 292.02 294.953 291.106 295.081 291.115 288.301 291.089 288.426 291.081 281.879 291.886 282.006 291.862 275.865 293.439 275.998 293.395 270.439 295.687 270.581 295.614 265.778 298.564 265.931 298.449 262.057 301.998 262.21 301.821 259.441 305.913ZM324.503 298.979 325.976 312.822 317.127 302.076Z" />
            <path d="M305.288 310.11 302.521 314.308 298.571 318.014 293.706 321.07 288.103 323.436 281.93 325.064 275.354 325.903 268.542 325.895 261.668 324.982 256.299 323.571 251.451 321.642 247.184 319.249 243.499 316.394 241.795 313.824 243.462 312.719 245.075 315.151 244.854 314.913 248.35 317.623 248.227 317.541 252.371 319.866 252.252 319.809 256.981 321.69 256.866 321.652 262.116 323.032 261.993 323.008 268.74 323.903 268.609 323.895 275.292 323.902 275.164 323.911 281.612 323.089 281.483 323.114 287.524 321.52 287.39 321.566 292.854 319.259 292.71 319.334 297.425 316.372 297.273 316.489 301.067 312.93 300.916 313.108 303.618 309.01ZM239.446 316.038 238.023 302.19 246.834 312.968Z" />
          </g>
        </svg>

        <DefaultNodeDecoration
          nodeType={NodeTypeEnum.NODE_REORDER_COL}
          comment={"Empty text on node"}
          textOnNode={""}
        />

        <PortWidget
          style={{
            top: this.props.height / 2 - 22,
            left: -25,
            position: "absolute",
          }}
          port={
            this.props.node.getPort("input") ||
            new SparkyELTPortModel(PortModelAlignment.LEFT, "input")
          }
          engine={this.props.engine}
        >
          <S.Port />
        </PortWidget>

        <PortWidget
          style={{
            top: this.props.height / 2 - 22,
            left: this.props.width - 14,
            position: "absolute",
          }}
          port={
            this.props.node.getPort("output") ||
            new SparkyELTPortModel(PortModelAlignment.RIGHT, "output")
          }
          engine={this.props.engine}
        >
          <S.Port />
        </PortWidget>
      </div>
    );
  }
}
