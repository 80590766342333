export enum ClusterStatus {
    STARTING = "STARTING",
    RUNNING = "RUNNING",
    BUSY = "BUSY",
    STOPPING = "STOPPING",
    TERMINATING = "TERMINATING",
    STOPPED = "STOPPED",
    TERMINATED = "TERMINATED",
    PENDING = "PENDING",
    ERROR = "ERROR"
}

export enum ClusterType {
    SPARKY = "SPARKY",
    CUSTOM = "CUSTOM"
}

export interface BaseClusterResponse {
    id: string;
    name: string;
    type: ClusterType;
    status: ClusterStatus;
}

export interface ClusterResponse extends BaseClusterResponse {
    autoShutdown?: boolean;
    connectionString?: string;
}

export interface RegisterSparkyClusterRequest {
    clusterName: string;
    clusterType: ClusterType.SPARKY;
    autoShutdown: boolean;
}

export interface RegisterCustomClusterRequest {
    clusterName: string;
    clusterType: ClusterType.CUSTOM;
    connectionString: string;
}