import {PortModelAlignment} from '@projectstorm/react-diagrams';
import {SparkyELTPortModel} from '../../port/SparkyELTPortModel';
import {SparkyBasicNodeModel} from "../SparkyBasicNodeModel";

export class AppendNodeModel extends SparkyBasicNodeModel {
    constructor() {
        super('Node_APPEND');
        this.addPort(new SparkyELTPortModel(PortModelAlignment.LEFT, 'inputTop'));
        this.addPort(new SparkyELTPortModel(PortModelAlignment.LEFT, 'inputBottom'));
        this.addPort(new SparkyELTPortModel(PortModelAlignment.RIGHT, 'output'));
    }
}