import {Box} from '@mui/material';
import * as React from 'react';
import {Route, BrowserRouter, Routes} from 'react-router-dom';
import {useAuthApiClient} from './clients/AuthApiClient';
import {useUserApiClient} from './clients/UserApiClient';
import {User} from './clients/model/User';
import GlobalLoader from './components/common/GlobalLoader';
import NotificationHandler from './components/notification/NotificationHandler';
import Page404 from './components/routing/Page404';
import RouteContent from './components/routing/RouteContent';
import {useUserContext} from './contexts/UserContext';
import {routes} from './tools/routes';

export interface IRootProps {
}

const Root: React.FunctionComponent<IRootProps> = (props: IRootProps) => {

    const userContext = useUserContext()

    const authApiClient = useAuthApiClient()
    const userApiClient = useUserApiClient()

    const [authorizing, setAuthorizing] = React.useState(true)

    React.useEffect(() => {
        if (!userContext.isLoggedIn() && authorizing) {
            userContext.setTokenFromCookies()
            authApiClient.isAuthorized()
                .then(() => {
                    return userApiClient.getCurrentUser()
                        .then((user: User) => {
                            userContext.setCurrentUser(user)
                        })
                })
                .catch(() => {
                })
                .finally(() => setAuthorizing(false))
        } else {
            setAuthorizing(false)
        }
    }, [authApiClient, authorizing, userApiClient, userContext])

    const Content = React.useMemo(() => {
        const displayedRoutes: JSX.Element[] = []
        routes.forEach(route => {
            displayedRoutes.push(<Route key={route.path} path={route.path} element={<RouteContent route={route}/>}/>)
        })

        displayedRoutes.push(<Route key="404" path="*" element={<RouteContent route={{
            child: < Page404/>,
            path: "*"
        }}/>}/>)

        if (authorizing) {
            return <GlobalLoader/>
        }

        return (
            <Box sx={{
                display: "flex",
                flexDirection: "column",
                // justifyContent: "space-between",
                minHeight: "100vh"
            }} component="div">
                <BrowserRouter>
                    <React.Suspense fallback={<GlobalLoader/>}>
                        <Routes>
                            {displayedRoutes}
                        </Routes>
                    </React.Suspense>
                </BrowserRouter>
            </Box>
        )

    }, [authorizing])
    return (
        <>
            {Content}
            <NotificationHandler/>
        </>
    );
}

export default Root;