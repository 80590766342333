import { Grid, Paper } from '@mui/material';
import * as React from 'react';
import { useNotificationContext } from '../../contexts/NotificationContext';
import FileExplorer from '../../components/common/FileExplorer';

export interface IStreamExplorerProps {
}

const DataExplorer: React.FunctionComponent<IStreamExplorerProps> = (props: IStreamExplorerProps) => {
    const notificationCtx = useNotificationContext()



    return (
        <>
            <Grid container justifyContent="center">
                <Grid item xs={10}>
                    <Paper sx={{
                        width: "100%",
                        height: "80vh",
                        padding: "1em",
                        backgroundColor: 'rgba(255, 255, 255, 0.2)',
                        backdropFilter: 'blur(3px)'
                    }}>
                        <Grid container>
                            <Grid item xs={12}>
                                <FileExplorer
                                    onFileClick={(f) => {
                                        if (f.downloadLink) {
                                            window.open(f.downloadLink)
                                        } else {
                                            notificationCtx.notify({
                                                message: `Could not generate download link for file ${f.name}`,
                                                severity: 'error'
                                            })
                                        }
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </>)
        ;
}


export default DataExplorer;