import * as React from 'react';
import {ReadNodeModel} from './ReadNodeModel';
import {DiagramEngine, PortModelAlignment, PortWidget} from '@projectstorm/react-diagrams';
import {SparkyELTPortModel} from "../../port/SparkyELTPortModel";
import {S} from '../../port/SimplePortWidget';
import {NodeTypeEnum} from "../NodeTypeEnum";
import DefaultNodeDecoration, {getPrimaryColorByNodeType} from "../DefaultNodeDecoration";
import ReadNodeSettings from "./settings/ReadNodeSettings";
import {useStreamContext} from "../../../../contexts/StreamContext";
import {SparkyNodeStatusInfo} from "../SparkyBasicNodeModel";

export interface ReadNodeWidgetProps {
    node: ReadNodeModel;
    engine: DiagramEngine;
    width: number;
    height: number;
}

const ReadNodeWidget: React.FC<ReadNodeWidgetProps> = (props: ReadNodeWidgetProps) => {

    const {selectedNodeIds, selectNodeIds} = useStreamContext();

    const [modalOpen, setModalOpen] = React.useState<boolean>(false);
    const [highlighted, setHighlighted] = React.useState<boolean>(false);
    const [comment, setComment] = React.useState<string>("");
    const [settingsSummary, setSettingsSummary] = React.useState<string>(props.node.getSettingsSummary());
    
    const handleOpen = () => {
        setModalOpen(true);
    };

    const handleClose = () => {
        setModalOpen(false);
    };

    const handleOnNodeClick = (event: React.MouseEvent<HTMLDivElement>) => {
        event.stopPropagation(); // Stop propagation to allow dragging
        if (event.shiftKey) { // multi selection
            if (selectedNodeIds.indexOf(props.node.getID()) > -1) {
                // remove from selected elements
                selectNodeIds(selectedNodeIds.filter(item => item !== props.node.getID()));
            } else {
                selectNodeIds([...selectedNodeIds, props.node.getID()])
            }
        } else { // single selection
            // selectNodeIds([props.node.getID()])
            if (selectedNodeIds.indexOf(props.node.getID()) > -1) {
                // selectNodeIds([])
            } else {
                selectNodeIds([props.node.getID()])
            }
            console.log("Shift not pressed " + props.node.getID())
        }
    }

    const [numLinesOut, setNumLinesOut] = React.useState<number | undefined>(undefined);
    React.useEffect(() => {
        const listener = props.node.registerListener({
            eventDidFire: (event: any) => {
                if (event.function === 'statusInfoUpdated') {
                    const info = event.statusInfo as SparkyNodeStatusInfo
                    if (info.count >= 0) {
                        setNumLinesOut(info.count)
                    }
                }
            },
        });
        return () => { listener.deregister(); };
    }, [props.node]);

    /*
    React.useEffect(() => {
        if (selectedNodeIds.indexOf(props.node.getID()) > -1) {
            setHighlighted(true)
        } else {
            setHighlighted(false)
        }
        // eslint-disable-next-line
    }, [selectedNodeIds])
    */

    React.useEffect(() => {
        if (props.node.isSelected()) {
            setHighlighted(true)
        } else {
            setHighlighted(false)
        }
        // eslint-disable-next-line
    }, [props.node.isSelected()])


    return (
        <div
            className={'diamond-node'}
            style={{
                position: 'relative',
                width: props.width,
                height: props.height,
            }}
            onDoubleClick={handleOpen}
            onClick={handleOnNodeClick}
        >
            <svg width="303" height="181" xmlns="http://www.w3.org/2000/svg"
                 xmlnsXlink="http://www.w3.org/1999/xlink" overflow="hidden">
                <defs>
                    <filter id="fx0-nodeRead" x="-10%" y="-10%" width="120%" height="120%" filterUnits="userSpaceOnUse"
                            primitiveUnits="userSpaceOnUse">
                        <feComponentTransfer colorInterpolationFilters="sRGB">
                            <feFuncR type="discrete" tableValues="0 0"/>
                            <feFuncG type="discrete" tableValues="0 0"/>
                            <feFuncB type="discrete" tableValues="0 0"/>
                            <feFuncA type="linear" slope="0.4" intercept="0"/>
                        </feComponentTransfer>
                        <feGaussianBlur stdDeviation="1.77778 1.77778"/>
                    </filter>
                    <filter id="fx1-nodeRead" x="-10%" y="-10%" width="120%" height="120%" filterUnits="userSpaceOnUse"
                            primitiveUnits="userSpaceOnUse">
                        <feComponentTransfer colorInterpolationFilters="sRGB">
                            <feFuncR type="discrete" tableValues="0 0"/>
                            <feFuncG type="discrete" tableValues="0 0"/>
                            <feFuncB type="discrete" tableValues="0 0"/>
                            <feFuncA type="linear" slope="0.4" intercept="0"/>
                        </feComponentTransfer>
                        <feGaussianBlur stdDeviation="1.77778 1.77778"/>
                    </filter>
                    <filter id="fx2-nodeRead" x="-10%" y="-10%" width="120%" height="120%" filterUnits="userSpaceOnUse"
                            primitiveUnits="userSpaceOnUse">
                        <feComponentTransfer colorInterpolationFilters="sRGB">
                            <feFuncR type="discrete" tableValues="0 0"/>
                            <feFuncG type="discrete" tableValues="0 0"/>
                            <feFuncB type="discrete" tableValues="0 0"/>
                            <feFuncA type="linear" slope="0.4" intercept="0"/>
                        </feComponentTransfer>
                        <feGaussianBlur stdDeviation="1.77778 1.77778"/>
                    </filter>
                    <filter id="fx3-nodeRead" x="-10%" y="-10%" width="120%" height="120%" filterUnits="userSpaceOnUse"
                            primitiveUnits="userSpaceOnUse">
                        <feComponentTransfer colorInterpolationFilters="sRGB">
                            <feFuncR type="discrete" tableValues="0 0"/>
                            <feFuncG type="discrete" tableValues="0 0"/>
                            <feFuncB type="discrete" tableValues="0 0"/>
                            <feFuncA type="linear" slope="0.4" intercept="0"/>
                        </feComponentTransfer>
                        <feGaussianBlur stdDeviation="1.77778 1.77778"/>
                    </filter>
                    <filter id="fx4-nodeRead" x="-10%" y="-10%" width="120%" height="120%" filterUnits="userSpaceOnUse"
                            primitiveUnits="userSpaceOnUse">
                        <feComponentTransfer colorInterpolationFilters="sRGB">
                            <feFuncR type="discrete" tableValues="0 0"/>
                            <feFuncG type="discrete" tableValues="0 0"/>
                            <feFuncB type="discrete" tableValues="0 0"/>
                            <feFuncA type="linear" slope="0.4" intercept="0"/>
                        </feComponentTransfer>
                        <feGaussianBlur stdDeviation="1.77778 1.77778"/>
                    </filter>
                    <filter id="fx5-nodeRead" x="-10%" y="-10%" width="120%" height="120%" filterUnits="userSpaceOnUse"
                            primitiveUnits="userSpaceOnUse">
                        <feComponentTransfer colorInterpolationFilters="sRGB">
                            <feFuncR type="discrete" tableValues="0 0"/>
                            <feFuncG type="discrete" tableValues="0 0"/>
                            <feFuncB type="discrete" tableValues="0 0"/>
                            <feFuncA type="linear" slope="0.4" intercept="0"/>
                        </feComponentTransfer>
                        <feGaussianBlur stdDeviation="1.77778 1.77778"/>
                    </filter>
                    <filter id="fx6-nodeRead" x="-10%" y="-10%" width="120%" height="120%" filterUnits="userSpaceOnUse"
                            primitiveUnits="userSpaceOnUse">
                        <feComponentTransfer colorInterpolationFilters="sRGB">
                            <feFuncR type="discrete" tableValues="0 0"/>
                            <feFuncG type="discrete" tableValues="0 0"/>
                            <feFuncB type="discrete" tableValues="0 0"/>
                            <feFuncA type="linear" slope="0.4" intercept="0"/>
                        </feComponentTransfer>
                        <feGaussianBlur stdDeviation="1.77778 1.77778"/>
                    </filter>
                    <filter id="fx7-nodeRead" x="-10%" y="-10%" width="120%" height="120%" filterUnits="userSpaceOnUse"
                            primitiveUnits="userSpaceOnUse">
                        <feComponentTransfer colorInterpolationFilters="sRGB">
                            <feFuncR type="discrete" tableValues="0 0"/>
                            <feFuncG type="discrete" tableValues="0 0"/>
                            <feFuncB type="discrete" tableValues="0 0"/>
                            <feFuncA type="linear" slope="0.4" intercept="0"/>
                        </feComponentTransfer>
                        <feGaussianBlur stdDeviation="1.77778 1.77778"/>
                    </filter>
                    <filter id="fx8-nodeRead" x="-10%" y="-10%" width="120%" height="120%" filterUnits="userSpaceOnUse"
                            primitiveUnits="userSpaceOnUse">
                        <feComponentTransfer colorInterpolationFilters="sRGB">
                            <feFuncR type="discrete" tableValues="0 0"/>
                            <feFuncG type="discrete" tableValues="0 0"/>
                            <feFuncB type="discrete" tableValues="0 0"/>
                            <feFuncA type="linear" slope="0.4" intercept="0"/>
                        </feComponentTransfer>
                        <feGaussianBlur stdDeviation="1.77778 1.77778"/>
                    </filter>
                    <clipPath id="clip9-nodeRead">
                        <rect x="274" y="499" width="303" height="181"/>
                    </clipPath>
                    <clipPath id="clip10-nodeRead">
                        <path
                            d="M390 508 390 666 538.529 666C553.148 666 565 654.21 565 639.666L565 534.334C565 519.79 553.148 508 538.529 508ZM274 499 577 499 577 680 274 680Z"
                            fillRule="evenodd" clipRule="evenodd"/>
                    </clipPath>
                    <clipPath id="clip11-nodeRead">
                        <path d="M351 508 351 666 376 666 376 508ZM274 499 577 499 577 680 274 680Z"
                              fillRule="evenodd" clipRule="evenodd"/>
                    </clipPath>
                    <clipPath id="clip12-nodeRead">
                        <path d="M311 506 311 534 336 534 336 506ZM274 499 577 499 577 680 274 680Z"
                              fillRule="evenodd" clipRule="evenodd"/>
                    </clipPath>
                    <clipPath id="clip13-nodeRead">
                        <path d="M284 620 284 647 309 647 309 620ZM274 499 577 499 577 680 274 680Z"
                              fillRule="evenodd" clipRule="evenodd"/>
                    </clipPath>
                    <clipPath id="clip14-nodeRead">
                        <path d="M309 639 309 667 334 667 334 639ZM274 499 577 499 577 680 274 680Z"
                              fillRule="evenodd" clipRule="evenodd"/>
                    </clipPath>
                    <clipPath id="clip15-nodeRead">
                        <path d="M281 522 281 550 306 550 306 522ZM274 499 577 499 577 680 274 680Z"
                              fillRule="evenodd" clipRule="evenodd"/>
                    </clipPath>
                    <clipPath id="clip16-nodeRead">
                        <path d="M283 575 283 602 308 602 308 575ZM274 499 577 499 577 680 274 680Z"
                              fillRule="evenodd" clipRule="evenodd"/>
                    </clipPath>
                    <clipPath id="clip17-nodeRead">
                        <path d="M308 545 308 572 333 572 333 545ZM274 499 577 499 577 680 274 680Z"
                              fillRule="evenodd" clipRule="evenodd"/>
                    </clipPath>
                    <clipPath id="clip18-nodeRead">
                        <path d="M314 594 314 622 339 622 339 594ZM274 499 577 499 577 680 274 680Z"
                              fillRule="evenodd" clipRule="evenodd"/>
                    </clipPath>
                </defs>
                <g clipPath="url(#clip9-nodeRead)" transform="translate(-274 -499)">
                    <g clipPath="url(#clip10-nodeRead)">
                        <g filter="url(#fx0-nodeRead)" transform="translate(382 500)">
                            <g>
                                <path
                                    d="M10.8284 10.8284 159.357 10.8284C173.977 10.8284 185.828 22.6185 185.828 37.1623L185.828 142.495C185.828 157.038 173.977 168.828 159.357 168.828L10.8284 168.828Z"
                                    stroke={highlighted ? "#333333" : "#FFFFFF"} strokeWidth="8" strokeMiterlimit="8"
                                    fill={getPrimaryColorByNodeType(NodeTypeEnum.NODE_MERGE)}
                                    fillRule="evenodd"/>
                            </g>
                        </g>
                    </g>
                    <path
                        d="M390 508 538.529 508C553.148 508 565 519.79 565 534.334L565 639.666C565 654.21 553.148 666 538.529 666L390 666Z"
                        stroke={highlighted ? "#333333" : "#FFFFFF"} strokeWidth="8" strokeMiterlimit="8"
                        fill={getPrimaryColorByNodeType(NodeTypeEnum.NODE_READ)} fillRule="evenodd"/>
                    <g clipPath="url(#clip11-nodeRead)">
                        <g filter="url(#fx1-nodeRead)" transform="translate(343 500)">
                            <g>
                                <rect x="10.8284" y="10.8284" width="25" height="158"
                                      stroke={highlighted ? "#333333" : "#FFFFFF"}
                                      strokeWidth="8" strokeMiterlimit="8"
                                      fill={getPrimaryColorByNodeType(NodeTypeEnum.NODE_READ)}/>
                            </g>
                        </g>
                    </g>
                    <rect x="351" y="508" width="25" height="158" stroke={highlighted ? "#333333" : "#FFFFFF"}
                          strokeWidth="8"
                          strokeMiterlimit="8" fill={getPrimaryColorByNodeType(NodeTypeEnum.NODE_READ)}/>
                    <g clipPath="url(#clip12-nodeRead)">
                        <g filter="url(#fx2-nodeRead)" transform="translate(303 498)">
                            <g>
                                <rect x="10.8284" y="10.8284" width="25" height="28"
                                      stroke={highlighted ? "#333333" : "#FFFFFF"}
                                      strokeWidth="8" strokeMiterlimit="8"
                                      fill={getPrimaryColorByNodeType(NodeTypeEnum.NODE_READ)}/>
                            </g>
                        </g>
                    </g>
                    <rect x="311" y="506" width="25" height="28" stroke={highlighted ? "#333333" : "#FFFFFF"}
                          strokeWidth="8"
                          strokeMiterlimit="8" fill={getPrimaryColorByNodeType(NodeTypeEnum.NODE_READ)}/>
                    <g clipPath="url(#clip13-nodeRead)">
                        <g filter="url(#fx3-nodeRead)" transform="translate(276 612)">
                            <g>
                                <rect x="10.8284" y="10.8284" width="25" height="27"
                                      stroke={highlighted ? "#333333" : "#FFFFFF"}
                                      strokeWidth="8" strokeMiterlimit="8"
                                      fill={getPrimaryColorByNodeType(NodeTypeEnum.NODE_READ)}/>
                            </g>
                        </g>
                    </g>
                    <rect x="284" y="620" width="25" height="27" stroke={highlighted ? "#333333" : "#FFFFFF"}
                          strokeWidth="8"
                          strokeMiterlimit="8" fill={getPrimaryColorByNodeType(NodeTypeEnum.NODE_READ)}/>
                    <g clipPath="url(#clip14-nodeRead)">
                        <g filter="url(#fx4-nodeRead)" transform="translate(301 631)">
                            <g>
                                <rect x="10.8284" y="10.8284" width="25" height="28"
                                      stroke={highlighted ? "#333333" : "#FFFFFF"}
                                      strokeWidth="8" strokeMiterlimit="8"
                                      fill={getPrimaryColorByNodeType(NodeTypeEnum.NODE_READ)}/>
                            </g>
                        </g>
                    </g>
                    <rect x="309" y="639" width="25" height="28" stroke={highlighted ? "#333333" : "#FFFFFF"}
                          strokeWidth="8"
                          strokeMiterlimit="8" fill={getPrimaryColorByNodeType(NodeTypeEnum.NODE_READ)}/>
                    <g clipPath="url(#clip15-nodeRead)">
                        <g filter="url(#fx5-nodeRead)" transform="translate(273 514)">
                            <g>
                                <rect x="10.8284" y="10.8284" width="25" height="28"
                                      stroke={highlighted ? "#333333" : "#FFFFFF"}
                                      strokeWidth="8" strokeMiterlimit="8"
                                      fill={getPrimaryColorByNodeType(NodeTypeEnum.NODE_READ)}/>
                            </g>
                        </g>
                    </g>
                    <rect x="281" y="522" width="25" height="28" stroke={highlighted ? "#333333" : "#FFFFFF"}
                          strokeWidth="8"
                          strokeMiterlimit="8" fill={getPrimaryColorByNodeType(NodeTypeEnum.NODE_READ)}/>
                    <g clipPath="url(#clip16-nodeRead)">
                        <g filter="url(#fx6-nodeRead)" transform="translate(275 567)">
                            <g>
                                <rect x="10.8284" y="10.8284" width="25" height="27"
                                      stroke={highlighted ? "#333333" : "#FFFFFF"}
                                      strokeWidth="8" strokeMiterlimit="8"
                                      fill={getPrimaryColorByNodeType(NodeTypeEnum.NODE_READ)}/>
                            </g>
                        </g>
                    </g>
                    <rect x="283" y="575" width="25" height="27" stroke={highlighted ? "#333333" : "#FFFFFF"}
                          strokeWidth="8"
                          strokeMiterlimit="8" fill={getPrimaryColorByNodeType(NodeTypeEnum.NODE_READ)}/>
                    <g clipPath="url(#clip17-nodeRead)">
                        <g filter="url(#fx7-nodeRead)" transform="translate(300 537)">
                            <g>
                                <rect x="10.8284" y="10.8284" width="25" height="27"
                                      stroke={highlighted ? "#333333" : "#FFFFFF"}
                                      strokeWidth="8" strokeMiterlimit="8"
                                      fill={getPrimaryColorByNodeType(NodeTypeEnum.NODE_READ)}/>
                            </g>
                        </g>
                    </g>
                    <rect x="308" y="545" width="25" height="27" stroke={highlighted ? "#333333" : "#FFFFFF"}
                          strokeWidth="8"
                          strokeMiterlimit="8" fill={getPrimaryColorByNodeType(NodeTypeEnum.NODE_READ)}/>
                    <g clipPath="url(#clip18-nodeRead)">
                        <g filter="url(#fx8-nodeRead)" transform="translate(306 586)">
                            <g>
                                <rect x="10.8284" y="10.8284" width="25" height="28"
                                      stroke={highlighted ? "#333333" : "#FFFFFF"}
                                      strokeWidth="8" strokeMiterlimit="8"
                                      fill={getPrimaryColorByNodeType(NodeTypeEnum.NODE_READ)}/>
                            </g>
                        </g>
                    </g>
                    <rect x="314" y="594" width="25" height="28" stroke={highlighted ? "#333333" : "#FFFFFF"}
                          strokeWidth="8"
                          strokeMiterlimit="8" fill={getPrimaryColorByNodeType(NodeTypeEnum.NODE_READ)}/>
                </g>
            </svg>

            <DefaultNodeDecoration
                nodeType={NodeTypeEnum.NODE_READ}
                comment={comment}
                textOnNode={settingsSummary}
                onDoubleClick={handleOpen}
                numLinesOut={numLinesOut}
            />

            <PortWidget
                style={{
                    top: props.height / 2 - 22,
                    left: props.width - 14,
                    position: 'absolute'
                }}
                port={props.node.getPort('output') || new SparkyELTPortModel(PortModelAlignment.RIGHT, 'output')}
                engine={props.engine}
            >
                <S.Port/>
            </PortWidget>

            <ReadNodeSettings open={modalOpen}
                              handleClose={handleClose}
                              nodeModel={props.node}
                              selectedConnectionId={props.node.getSelectedConnectionId()}
                              comment={comment}
                              setComment={setComment}
                              setSettingsSummary={setSettingsSummary}
            />
        </div>
    );
};

export default ReadNodeWidget;