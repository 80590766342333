import {
    PortModelAlignment,
} from "@projectstorm/react-diagrams";
import {SparkyELTPortModel} from "../../port/SparkyELTPortModel";
import {SparkyBasicNodeModel} from "../SparkyBasicNodeModel";


export type SparkyReplaceCondition = {
    inputColumn: string;
    fromValue: string;
    toValue: string;
    isRegex: boolean;
}

export class ReplaceValsNodeModel extends SparkyBasicNodeModel {

    replaceConditions: SparkyReplaceCondition[];

    constructor() {
        super("Node_REPLACE_VALS");
        this.addPort(new SparkyELTPortModel(PortModelAlignment.LEFT, "input"));
        this.addPort(new SparkyELTPortModel(PortModelAlignment.RIGHT, "output"));

        this.replaceConditions = [];
    }

    // called during stream resonstruction from file
    calculateOutputCols(): string[] {
        this.setOutputColumns(this.getInputColumns())
        return this.getOutputColumns()
    }

    serialize(): any {
        return {
            ...super.serialize(),
            outputColumns: this.outputColumns,
            comment: this.comment,

            replaceConditions: this.replaceConditions,
        };
    }

    deserialize(event: any): void {
        super.deserialize(event);
        this.outputColumns = event.data.outputColumns || [];
        this.comment = event.data.comment || "";

        this.replaceConditions = event.data.replaceConditions

        this.settingsSummary = this.calcSettingsSummary()
    }

    getReplaceConditions(): SparkyReplaceCondition[] {
        return this.replaceConditions;
    }

    setReplaceConditions(rules: SparkyReplaceCondition[]): void {
        this.replaceConditions = rules;
    }

    calcSettingsSummary() {
        const summary = this.replaceConditions
            .map((row) => {
                const mode = row.isRegex ? "RegEx" : "Replace";
                return `${row.inputColumn}: [${mode}] "${row.fromValue}" → "${row.toValue}"`;
            })
            .join(", ");
        return summary;
    }

}
