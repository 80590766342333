export enum DataSourceType {
    SPARKY = "SPARKY",
    FTP = "FTP",
    S3 = "S3"
}

export interface BaseDataSourceResponse {
    id: string;
    name: string;
    type: DataSourceType;
    owner: {
        id: number;
        username: string;
        email: string;
    };
}

export interface FTPMetadata {
    host: string;
    port: number;
    username: string;
    password?: string;
    privateKey?: string;
    passphrase?: string;
}

export interface S3Metadata {
    bucketName: string;
    region: string;
    accountId: string;
}

export interface FTPDataSourceResponse extends BaseDataSourceResponse {
    type: DataSourceType.FTP;
    metadata: FTPMetadata;
}

export interface S3DataSourceResponse extends BaseDataSourceResponse {
    type: DataSourceType.S3;
    metadata: S3Metadata;
}

export interface SparkyDataSourceResponse extends BaseDataSourceResponse {
    type: DataSourceType.SPARKY;
}

export type DataSourceResponse = FTPDataSourceResponse | S3DataSourceResponse | SparkyDataSourceResponse;

export interface RegisterDataSourceRequest {
    name: string;
    type: DataSourceType;
}

export interface RegisterFTPDataSourceRequest extends RegisterDataSourceRequest {
    type: DataSourceType.FTP;
    metadata: FTPMetadata;
}

export interface RegisterS3DataSourceRequest extends RegisterDataSourceRequest {
    type: DataSourceType.S3;
    metadata: S3Metadata;
}

export interface RegisterSparkyDataSourceRequest extends RegisterDataSourceRequest {
    type: DataSourceType.SPARKY;
} 