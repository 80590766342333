import MakeUniqueNodeWidget from "./MakeUniqueNodeWidget";
import { MakeUniqueNodeModel } from "./MakeUniqueNodeModel";
import * as React from "react";
import { AbstractReactFactory } from "@projectstorm/react-canvas-core";
import { DiagramEngine } from "@projectstorm/react-diagrams-core";
import { GenerateModelEvent } from "@projectstorm/react-canvas-core/dist/@types/core/AbstractModelFactory";
import { GenerateWidgetEvent } from "@projectstorm/react-canvas-core/dist/@types/core/AbstractReactFactory";

export class MakeUniqueNodeFactory extends AbstractReactFactory<
  MakeUniqueNodeModel,
  DiagramEngine
> {
  constructor() {
    super("Node_MAKE_UNIQUE");
  }

  generateReactWidget(event: GenerateWidgetEvent<any>): JSX.Element {
    return (
      <MakeUniqueNodeWidget
        engine={this.engine}
        width={303}
        height={179}
        node={event.model}
      />
    );
  }

  generateModel(event: GenerateModelEvent) {
    return new MakeUniqueNodeModel();
  }
}
