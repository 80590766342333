import { Button, Grid } from '@mui/material';
import * as React from 'react';
import { Link } from 'react-router-dom'
import { withAiAnimation } from '../../shared/AiAnimation';
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import { GiMatchHead } from "react-icons/gi";
import WhatsHot from "@mui/icons-material/Whatshot";

export interface IConfigurationsProps {
}

const Configuration: React.FunctionComponent<IConfigurationsProps> = (props: IConfigurationsProps) => {

    const theme = useTheme();
    const { t } = useTranslation();

    return (
        <>
            <Grid container alignItems="center" justifyContent="space-evenly" direction="row" sx={{
                alignItems: "center",
                height: "75vh"
            }}>

                <Grid item>
                    <Button
                        variant="outlined"
                        size="large"
                        component={Link}
                        to="/configuration/cluster"
                        sx={{
                            padding: "1em", fontSize: "16pt", borderRadius: "220px",
                            backgroundColor: 'rgba(255, 255, 255, 0.2)',
                            backdropFilter: 'blur(3px)',
                            boxShadow: '8px 8px 15px rgba(0, 0, 0, 0.2)',
                            border: '1px solid rgba(255, 255, 255, 0.2)',
                            width: '220px'
                        }}
                    >
                        <Grid container justifyContent="center" alignItems="center" textAlign="center" spacing={2}>
                            <Grid item xs={12}>
                                <GiMatchHead style={{ fontSize: 80, color: theme.palette.primary.main }} height="180px" />
                            </Grid>
                            <Grid item xs={12} sx={{ color: theme.palette.primary.main }}>
                                {t('configuration.cluster')}
                            </Grid>
                        </Grid>
                    </Button>
                </Grid>

                <Grid item>
                    <Button
                        variant="outlined"
                        size="large"
                        component={Link}
                        to="/configuration/datasources"
                        sx={{
                            padding: "1em", fontSize: "16pt", borderRadius: "220px",
                            backgroundColor: 'rgba(255, 255, 255, 0.2)',
                            backdropFilter: 'blur(3px)',
                            boxShadow: '8px 8px 15px rgba(0, 0, 0, 0.2)',
                            border: '1px solid rgba(255, 255, 255, 0.2)',
                            width: '220px'
                        }}
                    >
                        <Grid container justifyContent="center" alignItems="center" textAlign="center" spacing={2}>
                            <Grid item xs={12}>
                                <WhatsHot sx={{ fontSize: 100, color: theme.palette.primary.main }} height="180px" />
                            </Grid>
                            <Grid item xs={12} sx={{ color: theme.palette.primary.main }}>
                                {t('configuration.datasources')}
                            </Grid>
                        </Grid>
                    </Button>
                </Grid>
            </Grid>
        </>)
        ;
}

export default withAiAnimation(Configuration);