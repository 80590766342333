import { SxProps } from '@mui/material';
import { Box } from '@mui/system';
import * as React from 'react';

export interface ILogoProps {
    sx?: SxProps
}

const Logo: React.FunctionComponent<ILogoProps> = (props: ILogoProps) => {
    return (
        <Box component="img" src='/img/sparky_logo.png' sx={props.sx} />
    );
}

export default Logo;