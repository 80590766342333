import { PlayArrow } from '@mui/icons-material';
import { Fab } from '@mui/material';
import * as React from 'react';
import { useClusterContext } from '../../../contexts/ClusterContext';
import { ClusterStatus } from '../../../clients/model/Cluster';
import DisabledToolTip from '../../../components/common/DisabledToolTip';

export interface IStartStreamProps {
    saveStreamHandler: () => Promise<any>
    setStreamRunning: (running: boolean) => void 
}

const StartStream: React.FunctionComponent<IStartStreamProps> = (props: IStartStreamProps) => {
    const clusterCtx = useClusterContext();

    const start = () => {
        props.setStreamRunning(false)
        if (clusterCtx.selectedCluster) {
            props.saveStreamHandler().then(()=>{
                clusterCtx.startStream();    
                props.setStreamRunning(true)
            })
        }
    };

    const canExecute = clusterCtx.selectedCluster && 
        clusterCtx.selectedCluster.status === ClusterStatus.RUNNING;

    return (
        <>
            <DisabledToolTip
                disabled={!canExecute}
                tooltip="Cluster is not ready"
            >
                <Fab 
                    disabled={!canExecute} 
                    onClick={start} 
                    color="success"
                    sx={{ 
                        position: 'absolute', 
                        bottom: "10px", 
                        right: "50%", 
                        left: "50%", 
                        display: 'flex',
                        alignItems: 'center', 
                        justifyContent: 'center',
                        width: '100px',    
                        height: '100px',   
                    }}
                >
                    <PlayArrow style={{ fontSize: 50 }}/>
                </Fab>
            </DisabledToolTip>
        </>
    );
};

export default StartStream;