export enum NodeTypeEnum {
  NODE_READ = "NODE_READ",
  NODE_GROUP_BY = "NODE_GROUP_BY",
  NODE_REMOVE_COL = "NODE_REMOVE_COL",
  NODE_FILTER = "NODE_FILTER",
  NODE_APPEND = "NODE_APPEND",
  NODE_MERGE = "NODE_MERGE",
  NODE_SORT = "NODE_SORT",
  NODE_MAKE_UNIQUE = "NODE_MAKE_UNIQUE",
  NODE_RENAME_COL = "NODE_RENAME_COL",
  NODE_REORDER_COL = "NODE_REORDER_COL",
  NODE_CALCULATE = "NODE_CALCULATE",
  NODE_REPLACE_VALUE = "NODE_REPLACE_VALUE",
  NODE_WRITE = "NODE_WRITE",
  NODE_UNKNOWN = "NODE_UNKNOWN",
}
