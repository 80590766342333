import { TextField} from '@mui/material';
import * as React from 'react';
import {Box} from "@mui/system";
import {ChangeEvent} from "react";

export interface IGlobalDateSetterProps {
    globalDate: string,
    setGlobalDate(s: string): void,
}

const GlobalDateSetter: React.FunctionComponent<IGlobalDateSetterProps> = (props: IGlobalDateSetterProps) => {

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        props.setGlobalDate(value);
    };

    return (
        <>
            <Box
                sx={{
                    position: 'absolute',
                    right: "30px",
                    top: "120px",
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '200px',
                    backgroundColor: 'rgba(255,255,255, 0.8)'
                }}
            >
                <TextField fullWidth label="Stream's 'today' (yyyy-MM-dd)" name="globalDate"
                           value={props.globalDate} onChange={handleChange}
                />
            </Box>
        </>
    );
}

export default GlobalDateSetter;