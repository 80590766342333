import * as React from 'react';
import { ClusterStatus } from '../../../clients/model/Cluster';
import { DoNotDisturb, Error, CheckCircle, PauseCircle } from '@mui/icons-material';
import { CircularProgress, Tooltip } from '@mui/material';

export interface IClusterStatusIconProps {
    status?: {
        status: ClusterStatus;
    }
}

const ClusterStatusIcon: React.FunctionComponent<IClusterStatusIconProps> = (props: IClusterStatusIconProps) => {
    switch (props.status?.status) {
        case undefined:
            return <Tooltip title="No cluster available">
                <DoNotDisturb color="disabled" />
            </Tooltip>

        case ClusterStatus.STARTING:
            return <Tooltip title="Cluster is starting">
                <CircularProgress size="24px" color="info" />
            </Tooltip>

        case ClusterStatus.PENDING:
            return <Tooltip title="Cluster is pending">
                <CircularProgress size="24px" color="warning" />
            </Tooltip>

        case ClusterStatus.RUNNING:
            return <Tooltip title="Cluster is running">
                <CheckCircle color="success" />
            </Tooltip>

        case ClusterStatus.BUSY:
            return <Tooltip title="Cluster is busy">
                <CircularProgress size="24px" color="success" />
            </Tooltip>

        case ClusterStatus.TERMINATING:
            return <Tooltip title="Cluster is shutting down">
                <CircularProgress size="24px" color="error" />
            </Tooltip>

        case ClusterStatus.STOPPED:
            return <Tooltip title="Cluster is stopped">
                <PauseCircle color="warning" />
            </Tooltip>

        case ClusterStatus.TERMINATED:
            return <Tooltip title="Cluster is terminated">
                <DoNotDisturb color="error" />
            </Tooltip>

        case ClusterStatus.ERROR:
            return <Tooltip title="Cluster encountered an error">
                <Error color="error" />
            </Tooltip>

        default:
            return <Tooltip title={`Unknown status: ${props.status?.status}`}>
                <DoNotDisturb color="disabled" />
            </Tooltip>
    }
}

export default ClusterStatusIcon;