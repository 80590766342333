import { LinkModel, PortModel, PortModelAlignment } from '@projectstorm/react-diagrams';
import { CustomLinkModel } from '../link/CustomLinkModel';

export class SparkyELTPortModel extends PortModel {
    name: string = "";

    constructor(alignment: PortModelAlignment, name: string) {
        super({
            type: 'diamond',
            name: name,
            alignment: alignment
        });
        this.name = name;
    }

    // Override to use CustomLinkModel
    createLinkModel(): LinkModel {
        return new CustomLinkModel(); // Use your custom link model here
    }

    serialize(): any {
        return {
            ...super.serialize(),
            name: this.name
        };
    }

    deserialize(event: any): void {
        super.deserialize(event);
        this.name = event.data.name;
    }
}
