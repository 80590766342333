import * as React from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { Fab, Grid, IconButton, Paper, TextField, Tooltip, Typography } from "@mui/material";
import { Add, Delete, PlayArrow, Stop } from "@mui/icons-material";
import { useClusterClient } from '../../../clients/ClusterClient';
import { BaseClusterResponse, ClusterType } from '../../../clients/model/Cluster';
import { useCallback, useEffect, useState, useRef } from 'react';
import { CreateClusterModal } from './components/CreateClusterModal';
import ClusterStatusIcon from '../../cluster/components/ClusterStatusIcon';

export interface IClusterConfigProps {
}

const ClusterConfig: React.FunctionComponent<IClusterConfigProps> = (props: IClusterConfigProps) => {
    const [clusters, setClusters] = React.useState<BaseClusterResponse[]>([]);
    const [filteredClusters, setFilteredClusters] = React.useState<BaseClusterResponse[]>([]);
    const clusterClient = useClusterClient();
    const [createModalOpen, setCreateModalOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const intervalRef = useRef<NodeJS.Timeout>();

    const loadClusters = useCallback(async () => {
        try {
            const response = await clusterClient.getAllClusters();
            setClusters(response);
            const filtered = response.filter(cluster => 
                cluster.name.toLowerCase().includes(searchTerm.toLowerCase())
            );
            setFilteredClusters(filtered);
        } catch (error) {
            console.error('Failed to load clusters:', error);
        }
    }, [clusterClient, searchTerm]);
    
    useEffect(() => {
        loadClusters();
        intervalRef.current = setInterval(loadClusters, 30000);
        return () => {
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
            }
        };
    }, [loadClusters]);

    const handleDelete = async (clusterId: string) => {
        try {
            await clusterClient.deleteCluster(clusterId);
            await loadClusters(); // Reload the list after deletion
        } catch (error) {
            console.error('Failed to delete cluster:', error);
        }
    };

    const handleCreateCluster = async (values: {
        clusterName: string;
        clusterType: ClusterType;
        autoShutdown?: boolean;
        connectionString?: string;
    }) => {
        try {
            if (values.clusterType === ClusterType.SPARKY) {
                await clusterClient.registerSparkyCluster({
                    clusterName: values.clusterName,
                    clusterType: ClusterType.SPARKY,
                    autoShutdown: values.autoShutdown ?? true
                });
            } else {
                await clusterClient.registerCustomCluster({
                    clusterName: values.clusterName,
                    clusterType: ClusterType.CUSTOM,
                    connectionString: values.connectionString!
                });
            }
            await loadClusters();
            setCreateModalOpen(false);
        } catch (error) {
            console.error('Failed to create cluster:', error);
        }
    };

    const handleStartCluster = async (clusterId: string) => {
        try {
            await clusterClient.startCluster(clusterId);
            await loadClusters();
        } catch (error) {
            console.error('Failed to start cluster:', error);
        }
    };

    const handleStopCluster = async (clusterId: string) => {
        try {
            await clusterClient.stopCluster(clusterId);
            await loadClusters();
        } catch (error) {
            console.error('Failed to stop cluster:', error);
        }
    };

    const columns: GridColDef[] = [
        { field: 'id', headerName: 'ID', width: 220 },
        { field: 'name', headerName: 'Cluster Name', width: 150 },
        { field: 'type', headerName: 'Type', width: 120 },
        { 
            field: 'status', 
            headerName: 'Status', 
            width: 150,
            renderCell: (params) => (
                <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                    <ClusterStatusIcon status={params.row} />
                    <span>{params.row.status}</span>
                </div>
            )
        },
        {
            field: 'actions',
            headerName: 'Actions',
            width: 180,
            sortable: false,
            renderCell: (params) => {
                const showStartButton = ['TERMINATED', 'STOPPED', 'PENDING'].includes(params.row.status);
                const showStopButton = ['RUNNING', 'BUSY', 'ERROR', 'TERMINATING', 'STARTING'].includes(params.row.status);

                return (
                    <div style={{ display: 'flex', gap: '8px' }}>
                        {params.row.type === 'SPARKY' && (
                            showStartButton ? (
                                <Tooltip title="Start Cluster">
                                    <span>
                                        <IconButton
                                            onClick={() => handleStartCluster(params.row.id)}
                                            color="primary"
                                            disabled={params.row.status === 'PENDING'}
                                        >
                                            <PlayArrow />
                                        </IconButton>
                                    </span>
                                </Tooltip>
                            ) : showStopButton ? (
                                <Tooltip title="Stop Cluster">
                                    <span>
                                        <IconButton
                                            onClick={() => handleStopCluster(params.row.id)}
                                            color="error"
                                            disabled={params.row.status === 'TERMINATING'}
                                        >
                                            <Stop />
                                        </IconButton>
                                    </span>
                                </Tooltip>
                            ) : null
                        )}
                        <Tooltip title="Delete Cluster">
                            <IconButton
                                onClick={() => handleDelete(params.row.id)}
                                color="error"
                            >
                                <Delete />
                            </IconButton>
                        </Tooltip>
                    </div>
                );
            },
        }
    ];

    const handleSearch = (newSearchTerm: string) => {
        setSearchTerm(newSearchTerm);
        const filtered = clusters.filter(cluster => 
            cluster.name.toLowerCase().includes(newSearchTerm.toLowerCase())
        );
        setFilteredClusters(filtered);
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography variant="h3">
                    Cluster Configuration
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Paper sx={{
                    padding: "1em",
                }}>
                    <Grid item xs={12} container spacing={5}>
                        <Grid item xs>
                            <TextField
                                label="Search Clusters"
                                variant="outlined"
                                fullWidth
                                sx={{ marginRight: "1em" }}
                                onChange={(e) => handleSearch(e.target.value)}
                            />
                        </Grid>
                        <Grid item>
                            <Tooltip title="Add Cluster">
                                <Fab
                                    color="primary"
                                    onClick={() => setCreateModalOpen(true)}
                                >
                                    <Add/>
                                </Fab>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
            <Grid item xs={12}>
                <Paper sx={{
                    height: "60vh",
                    padding: "1em",
                    display: "flex",
                    flexDirection: "column",
                    msFlexDirection: "column",
                }}>
                    <DataGrid
                        rows={filteredClusters}
                        columns={columns}
                        disableColumnFilter
                        disableColumnSorting
                        autoPageSize
                    />
                </Paper>
            </Grid>
            <CreateClusterModal
                open={createModalOpen}
                onClose={() => setCreateModalOpen(false)}
                onSubmit={handleCreateCluster}
            />
        </Grid>
    );
}

export default ClusterConfig;