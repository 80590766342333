import {
    PortModelAlignment,
} from "@projectstorm/react-diagrams";
import {SparkyELTPortModel} from "../../port/SparkyELTPortModel";
import {SparkyBasicNodeModel} from "../SparkyBasicNodeModel";

export class MakeUniqueNodeModel extends SparkyBasicNodeModel {
    
    uniqueColumns: string[];
    
    constructor() {
        super("Node_MAKE_UNIQUE");
        this.addPort(new SparkyELTPortModel(PortModelAlignment.LEFT, "input"));
        this.addPort(new SparkyELTPortModel(PortModelAlignment.RIGHT, "output"));

        this.uniqueColumns = [];
    }

    // called during stream resonstruction from file
    calculateOutputCols(): string[] {
        this.setOutputColumns(this.getInputColumns())
        return this.getOutputColumns()
    }

    serialize(): any {
        return {
            ...super.serialize(),
            comment: this.comment,
            
            uniqueColumns: this.uniqueColumns,
        };
    }

    deserialize(event: any): void {
        super.deserialize(event);
        this.comment = event.data.comment || "";

        this.uniqueColumns = event.data.uniqueColumns || [];
        
        this.settingsSummary = this.calcSettingsSummary()
    }

    getUniqueColumns(): string[] {
        return this.uniqueColumns;
    }

    setUniqueColumns(cols: string[]): void {
        this.uniqueColumns = cols;
    }

    calcSettingsSummary() {
        let settingsSummary = ""
        for (let i = 0; i < this.uniqueColumns.length; i++) {
            if (this.uniqueColumns[i].length === 0) {
                continue
            }
            settingsSummary += this.uniqueColumns[i] + ", "
        }
        if (settingsSummary.length > 0) {
            settingsSummary = "" + settingsSummary
        }
        return settingsSummary;
    }
    
}
