import {NodeModel, NodeModelGenerics} from '@projectstorm/react-diagrams';
import {SparkyELTPortModel} from '../port/SparkyELTPortModel';

export interface DiamondNodeModelGenerics {
    PORT: SparkyELTPortModel;
}

export type SparkyNodeStatusInfo = {
    count: number,
    extraInfo: string,
}

export abstract class SparkyBasicNodeModel extends NodeModel<NodeModelGenerics & DiamondNodeModelGenerics> {
    
    inputColumns: string[];
    outputColumns: string[];
    comment: string;
    settingsSummary: string;
    statusInfo: SparkyNodeStatusInfo;

    protected constructor(type: string) {
        super({type});
        this.inputColumns = [];
        this.outputColumns = [];
        this.comment = '';
        this.settingsSummary = '';
        this.statusInfo = {count: -1, extraInfo: ""};
    }

    // Shared methods for all node models

    getInputColumns(): string[] {
        return this.inputColumns;
    }

    setInputColumns(cols: string[]): void {
        this.inputColumns = cols;
    }
    
    getOutputColumns(): string[] {
        return this.outputColumns;
    }

    setOutputColumns(cols: string[]): void {
        this.outputColumns = cols;
    }

    getComment(): string {
        return this.comment;
    }

    setComment(comment: string): void {
        this.comment = comment;
    }

    getSettingsSummary(): string {
        return this.settingsSummary;
    }

    setSettingsSummary(settingsSummary: string): void {
        this.settingsSummary = settingsSummary;
    }

    getStatusInfo(): SparkyNodeStatusInfo {
        return this.statusInfo;
    }

    setStatusInfo(info: SparkyNodeStatusInfo): void {
        this.statusInfo = info;
    }

    
}