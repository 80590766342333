import ApiClient, { ClientOptions } from "./ApiClient";
import { createContext, useContext } from "react";
import { useNotificationContext } from "../contexts/NotificationContext";
import {
    ClusterResponse,
    RegisterCustomClusterRequest,
    RegisterSparkyClusterRequest
} from "./model/Cluster";

export default class ClusterClient extends ApiClient {

    public getClusterLogs = async (clusterId: string, options?: ClientOptions): Promise<string> => {
        return this.get(`/cluster/${clusterId}/cluster-logs`, options);
    }

    public getSparkLogs = async (clusterId: string, options?: ClientOptions): Promise<string> => {
        return this.get(`/cluster/${clusterId}/spark-logs`, options);
    }

    public registerCustomCluster = async (request: RegisterCustomClusterRequest, options?: ClientOptions): Promise<ClusterResponse> => {
        return this.post('/cluster/register', { body: request, ...options });
    }

    public registerSparkyCluster = async (request: RegisterSparkyClusterRequest, options?: ClientOptions): Promise<ClusterResponse> => {
        return this.post('/cluster/register', { body: request, ...options });
    }

    public getCluster = async (id: string, options?: ClientOptions): Promise<ClusterResponse> => {
        return this.get(`/cluster/${id}`, options);
    }

    public deleteCluster = async (id: string, options?: ClientOptions): Promise<void> => {
        return this.delete(`/cluster/${id}`, options);
    }

    public getAllClusters = async (options?: ClientOptions): Promise<ClusterResponse[]> => {
        return this.get('/cluster/all', options);
    }

    public startCluster = async (clusterId: string, options?: ClientOptions): Promise<void> => {
        return this.post(`/cluster/${clusterId}/start`, options);
    }

    public stopCluster = async (clusterId: string, options?: ClientOptions): Promise<void> => {
        return this.post(`/cluster/${clusterId}/stop`, options);
    }
}

const ClusterClientContext = createContext<ClusterClient>(new ClusterClient());


export const useClusterClient = (defaultConfig?: ClientOptions) => {
    const context = useContext(ClusterClientContext)
    context.notificationCtx = useNotificationContext()
    if (defaultConfig) {
        context.defaultConfig = defaultConfig
    }
    return context
}