import ApiClient, { ClientOptions } from "./ApiClient";
import { createContext, useContext } from "react";
import { useNotificationContext } from "../contexts/NotificationContext";
import {
    DataSourceResponse,
    RegisterFTPDataSourceRequest,
    RegisterS3DataSourceRequest,
    RegisterSparkyDataSourceRequest
} from "./model/DataSource";

export default class DataSourceClient extends ApiClient {
    public getAllDataSources = async (options?: ClientOptions): Promise<DataSourceResponse[]> => {
        return this.get('/datasource/', options);
    }

    public getDataSource = async (id: string, options?: ClientOptions): Promise<DataSourceResponse> => {
        return this.get(`/datasource/${id}`, options);
    }

    public deleteDataSource = async (id: string, options?: ClientOptions): Promise<void> => {
        return this.delete(`/datasource/${id}`, options);
    }

    public registerFTPDataSource = async (request: RegisterFTPDataSourceRequest, options?: ClientOptions): Promise<DataSourceResponse> => {
        return this.post('/datasource/register', { body: request, ...options });
    }

    public registerS3DataSource = async (request: RegisterS3DataSourceRequest, options?: ClientOptions): Promise<DataSourceResponse> => {
        return this.post('/datasource/register', { body: request, ...options });
    }

    public registerSparkyDataSource = async (request: RegisterSparkyDataSourceRequest, options?: ClientOptions): Promise<DataSourceResponse> => {
        return this.post('/datasource/register', { body: request, ...options });
    }
}

const DataSourceClientContext = createContext<DataSourceClient>(new DataSourceClient());

export const useDataSourceClient =(defaultConfig?: ClientOptions) => {
    const context = useContext(DataSourceClientContext)
    context.notificationCtx = useNotificationContext()
    if (defaultConfig) {
        context.defaultConfig = defaultConfig
    }
    return context
}