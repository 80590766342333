import {
    PortModelAlignment,
} from "@projectstorm/react-diagrams";
import {SparkyELTPortModel} from "../../port/SparkyELTPortModel";
import {SparkyBasicNodeModel} from "../SparkyBasicNodeModel";

export class RemoveColNodeModel extends SparkyBasicNodeModel {

    removedColumns: string[];
    renameMap: Record<string, string>;

    constructor() {
        super("Node_REMOVE_COL");
        this.addPort(new SparkyELTPortModel(PortModelAlignment.LEFT, "input"));
        this.addPort(new SparkyELTPortModel(PortModelAlignment.RIGHT, "output"));

        this.removedColumns = [];
        this.renameMap = {};
    
    }

    // called during stream resonstruction from file
    calculateOutputCols(): string[] {
        const outCols: string[] = []
        this.inputColumns.forEach((colName: string, idx: number) => {
            if (!this.removedColumns.includes(colName)) {
                if (Object.keys(this.renameMap).includes(colName)) {
                    outCols.push(this.renameMap[colName])
                } else {
                    outCols.push(colName)    
                }
            }
        })
        this.setOutputColumns(outCols)
        return this.getOutputColumns()
    }

    serialize(): any {
        return {
            ...super.serialize(),
            comment: this.comment,
            
            removedColumns: this.removedColumns,
            renameMap: this.renameMap,
        };
    }

    deserialize(event: any): void {
        super.deserialize(event);
        this.comment = event.data.comment;
        
        this.removedColumns = event.data.removedColumns;
        this.renameMap = event.data.renameMap || {};

        this.settingsSummary = this.calcSettingsSummary()
    }

    getRemovedColumns(): string[] {
        return this.removedColumns;
    }

    setRemovedColumns(cols: string[]): void {
        this.removedColumns = cols;
    }

    getRenameMap(): Record<string, string> {
        return this.renameMap;
    }

    setRenameMap(map: Record<string, string>): void {
        this.renameMap = map;
    }

    calcSettingsSummary() {
        
        let settingsSummary = ""
        if (this.removedColumns.length <= 2) {
            settingsSummary += this.removedColumns.join(", ");
        } else if (this.removedColumns.length > 2) {
            settingsSummary += " remove " + this.removedColumns.length + " cols"
        }

        if (Object.keys(this.renameMap).length > 0) {
            settingsSummary += ", rename " + Object.keys(this.renameMap).length + " cols"
        }
        
        return settingsSummary;
    }
}
