import React, {useState} from "react";
import {
    Dialog,
    DialogActions,
    DialogContent,
    Button,
    FormControl,
    TextField,
    Grid,
    Tabs,
    Tab,
    Box,
    Select,
    MenuItem,
    IconButton,
    Autocomplete,
} from "@mui/material";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import DialogHeader from "../../../../../shared/components/dialogs/DialogHeader";
import {
    BasicCalculation,
    CalculateNodeModel,
    DateCalculation,
    DaysUntilCalculation,
    ValueType
} from "../CalculateNodeModel";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

interface CalculateNodeSettingsProps {
    open: boolean;
    handleClose: () => void;
    nodeModel: CalculateNodeModel;
    comment: string;
    setComment: React.Dispatch<React.SetStateAction<string>>;
    setSettingsSummary: React.Dispatch<React.SetStateAction<string>>;
}

const dateFormats = [
    "yyyy-MM-dd",
    "dd/MM/yyyy",
    "dd-MM-yyyy",
    "yyyy/MM/dd",
    "yyyy.MM.dd",
    "dd.MM.yyyy",
];
const units = ["year", "month", "day"];
const operators = ["+", "-"];
const operations = ["+", "-", "*", "/"];

const CalculateNodeSettings: React.FC<CalculateNodeSettingsProps> = (
    props: CalculateNodeSettingsProps
) => {
    const dialogContentRef = React.useRef<HTMLDivElement>(null);
    const [tabIndex, setTabIndex] = useState(0);
    const [dateCalculations, setDateCalculations] = useState<DateCalculation[]>([
        {
            column: "",
            dateFormat: "",
            operator: "+",
            value: 1,
            unit: "day",
            outputColumn: "",
        },
    ]);

    const [daysUntilCalculations, setDaysUntilCalculations] = useState<
        DaysUntilCalculation[]
    >([
        {
            column: "",
            targetDate: "",
            dateFormat: "",
            outputColumn: "",
        },
    ]);
    
    const [basicCalculations, setBasicCalculations] = useState<
        BasicCalculation[]
    >([
        {
            column: "",
            operation: "",
            value: {type: "string", value: ""},
            outputColumn: "",
        },
    ]);
    
    const onClose = () => {
        props.handleClose && props.handleClose();
    };

    const onApply = () => {
        // Filtere leere oder unvollständige Einträge aus den Berechnungen
        const filteredBasicCalculations: BasicCalculation[] = basicCalculations.filter(
                (calc) =>
                    calc.column && calc.operation && calc.value && calc.outputColumn
        );

        const filteredDateCalculations = dateCalculations.filter(
            (calc) =>
                calc.column &&
                calc.dateFormat &&
                calc.operator &&
                calc.value !== undefined &&
                calc.unit &&
                calc.outputColumn
        );

        const filteredDaysUntilCalculations = daysUntilCalculations.filter(
            (calc) =>
                calc.column && calc.targetDate && calc.dateFormat && calc.outputColumn
        );
        
        // --
        props.nodeModel.setBasicCalculations(filteredBasicCalculations);
        props.nodeModel.setDateCalculations(filteredDateCalculations);
        props.nodeModel.setDaysUntilCalculations(filteredDaysUntilCalculations);
        // --

        props.nodeModel.setOutputColumns(props.nodeModel.calculateOutputCols());
        props.nodeModel.setOutputColumns(props.nodeModel.calculateOutputCols());
        props.nodeModel.setComment(props.comment);
        props.nodeModel.setSettingsSummary(props.nodeModel.calcSettingsSummary());
        props.setSettingsSummary(props.nodeModel.getSettingsSummary())

        onClose();
    };

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabIndex(newValue);
    };

    const updateBasicCalculation = (
        index: number,
        key: "column" | "operation" | "value" | "outputColumn",
        value: string | ValueType
    ) => {
        const newCalculations = [...basicCalculations];
        if (key === "value") {
            newCalculations[index].value = value as ValueType;
        } else {
            newCalculations[index][key] = value as string;
        }
        setBasicCalculations(newCalculations);
    };

    const updateDateCalculation = (
        index: number,
        key: keyof DateCalculation,
        value: string | number
    ) => {
        const newCalculations = [...dateCalculations];

        if (key === "value" && typeof value === "number") {
            newCalculations[index][key] = value;
        } else if (
            (key === "column" ||
                key === "dateFormat" ||
                key === "operator" ||
                key === "unit" ||
                key === "outputColumn") &&
            typeof value === "string"
        ) {
            newCalculations[index][key] = value;
        } else {
            console.error(`Invalid type for key ${key}: ${typeof value}`);
        }

        setDateCalculations(newCalculations);
    };

    const updateDaysUntilCalculation = (
        index: number,
        key: keyof DaysUntilCalculation,
        value: string
    ) => {
        const newCalculations = [...daysUntilCalculations];
        newCalculations[index][key] = value;
        setDaysUntilCalculations(newCalculations);
    };

    const addDateCalculation = () => {
        setDateCalculations([
            ...dateCalculations,
            {
                column: "",
                dateFormat: "yyyy-MM-dd",
                operator: "+",
                value: 1,
                unit: "day",
                outputColumn: "",
            },
        ]);
    };

    const addDaysUntilCalculation = () => {
        setDaysUntilCalculations([
            ...daysUntilCalculations,
            {
                column: "",
                targetDate: "",
                dateFormat: "",
                outputColumn: "",
            },
        ]);
    };

    const removeDateCalculation = (index: number) => {
        const newCalculations = [...dateCalculations];
        newCalculations.splice(index, 1);
        setDateCalculations(newCalculations);
    };

    const removeDaysUntilCalculation = (index: number) => {
        const newCalculations = [...daysUntilCalculations];
        newCalculations.splice(index, 1);
        setDaysUntilCalculations(newCalculations);
    };

    const addBasicCalculation = () => {
        setBasicCalculations([
            ...basicCalculations,
            {
                column: "",
                operation: "",
                value: {type: "string", value: ""},
                outputColumn: "",
            },
        ]);
    };

    const removeBasicCalculation = (index: number) => {
        const newCalculations = [...basicCalculations];
        newCalculations.splice(index, 1);
        setBasicCalculations(newCalculations);
    };


    // used to re-load the filter-settings after the stream was reconstructed from file
    React.useEffect(() => {
        props.setComment(props.nodeModel.comment)
        // eslint-disable-next-line
    }, [props.nodeModel.comment])
    React.useEffect(() => {
        setBasicCalculations(props.nodeModel.basicCalculations)
        // eslint-disable-next-line
    }, [props.nodeModel.basicCalculations])
    React.useEffect(() => {
        setDateCalculations(props.nodeModel.dateCalculations)
        // eslint-disable-next-line
    }, [props.nodeModel.dateCalculations])
    React.useEffect(() => {
        setDaysUntilCalculations(props.nodeModel.daysUntilCalculations)
        // eslint-disable-next-line
    }, [props.nodeModel.daysUntilCalculations])

    return (
        <Dialog
            open={props.open}
            onClose={onClose}
            maxWidth="lg"
            scroll="paper"
            PaperProps={{sx: {backgroundColor: "white"}}}
            disableEnforceFocus
            fullWidth
        >
            <DialogHeader headline={"Calculate Rows"} closeDialog={onClose}/>
            <DialogContent ref={dialogContentRef}>
                <Box sx={{borderBottom: 1, borderColor: "divider", marginBottom: 2}}>
                    <Tabs
                        value={tabIndex}
                        onChange={handleTabChange}
                        aria-label="Calculate Node Tabs"
                    >
                        <Tab label="Datumsberechnung"/>
                        <Tab label="Tage bis Datenstand"/>
                        <Tab label="Formeln berechnen"/>
                        <Tab label="Grundrechenarten"/>
                    </Tabs>
                </Box>
                {tabIndex === 0 && (
                    <Box>
                        {dateCalculations.map((calc, index) => (
                            <Grid
                                container
                                spacing={2}
                                key={index}
                                sx={{marginBottom: 2}}
                                alignItems="center"
                            >
                                <Grid item xs={2}>
                                    <Select
                                        value={calc.column}
                                        onChange={(e) =>
                                            updateDateCalculation(index, "column", e.target.value)
                                        }
                                        displayEmpty
                                        fullWidth
                                    >
                                        <MenuItem value="" disabled>
                                            Select Column
                                        </MenuItem>
                                        {props.nodeModel.getInputColumns().map((col) => (
                                            <MenuItem key={col} value={col}>
                                                {col}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </Grid>
                                <Grid item xs={2}>
                                    <Select
                                        value={calc.dateFormat}
                                        onChange={(e) =>
                                            updateDateCalculation(index, "dateFormat", e.target.value)
                                        }
                                        displayEmpty
                                        fullWidth
                                    >
                                        <MenuItem value="" disabled>
                                            Select Format
                                        </MenuItem>
                                        {dateFormats.map((format) => (
                                            <MenuItem key={format} value={format}>
                                                {format}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </Grid>
                                <Grid item xs={1}>
                                    <Select
                                        value={calc.operator}
                                        onChange={(e) =>
                                            updateDateCalculation(index, "operator", e.target.value)
                                        }
                                        displayEmpty
                                        fullWidth
                                    >
                                        {operators.map((op) => (
                                            <MenuItem key={op} value={op}>
                                                {op}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </Grid>
                                <Grid item xs={1}>
                                    <TextField
                                        type="number"
                                        value={calc.value}
                                        onChange={(e) =>
                                            updateDateCalculation(
                                                index,
                                                "value",
                                                Number(e.target.value)
                                            )
                                        }
                                        placeholder="Value"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <Select
                                        value={calc.unit}
                                        onChange={(e) =>
                                            updateDateCalculation(index, "unit", e.target.value)
                                        }
                                        displayEmpty
                                        fullWidth
                                    >
                                        <MenuItem value="" disabled>
                                            Select Unit
                                        </MenuItem>
                                        {units.map((unit) => (
                                            <MenuItem key={unit} value={unit}>
                                                {unit}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField
                                        placeholder="New Column Name"
                                        value={calc.outputColumn}
                                        onChange={(e) =>
                                            updateDateCalculation(
                                                index,
                                                "outputColumn",
                                                e.target.value
                                            )
                                        }
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={1}>
                                    <IconButton
                                        onClick={() => removeDateCalculation(index)}
                                        color="error"
                                    >
                                        <RemoveCircleOutlineIcon/>
                                    </IconButton>
                                </Grid>
                            </Grid>
                        ))}
                        <IconButton onClick={addDateCalculation} color="success">
                            <AddCircleOutlineIcon/>
                        </IconButton>
                    </Box>
                )}

                {tabIndex === 1 && (
                    <Box>
                        {daysUntilCalculations.map((calc, index) => (
                            <Grid
                                container
                                spacing={2}
                                key={index}
                                sx={{marginBottom: 2}}
                                alignItems="center"
                            >
                                <Grid item xs={2}>
                                    <Select
                                        value={calc.column}
                                        onChange={(e) =>
                                            updateDaysUntilCalculation(
                                                index,
                                                "column",
                                                e.target.value
                                            )
                                        }
                                        displayEmpty
                                        fullWidth
                                    >
                                        <MenuItem value="" disabled>
                                            Select Column
                                        </MenuItem>
                                        {props.nodeModel.getInputColumns().map((col) => (
                                            <MenuItem key={col} value={col}>
                                                {col}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </Grid>
                                <Grid item xs={2}>
                                    <Select
                                        value={calc.dateFormat || ""}
                                        onChange={(e) =>
                                            updateDaysUntilCalculation(
                                                index,
                                                "dateFormat",
                                                e.target.value
                                            )
                                        }
                                        displayEmpty
                                        fullWidth
                                    >
                                        <MenuItem value="" disabled>
                                            Select Format
                                        </MenuItem>
                                        {dateFormats.map((format) => (
                                            <MenuItem key={format} value={format}>
                                                {format}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField
                                        type="date"
                                        value={calc.targetDate}
                                        onChange={(e) =>
                                            updateDaysUntilCalculation(
                                                index,
                                                "targetDate",
                                                e.target.value
                                            )
                                        }
                                        placeholder="Target Date"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        placeholder="New Column Name"
                                        value={calc.outputColumn}
                                        onChange={(e) =>
                                            updateDaysUntilCalculation(
                                                index,
                                                "outputColumn",
                                                e.target.value
                                            )
                                        }
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={1}>
                                    <IconButton
                                        onClick={() => removeDaysUntilCalculation(index)}
                                        color="error"
                                    >
                                        <RemoveCircleOutlineIcon/>
                                    </IconButton>
                                </Grid>
                            </Grid>
                        ))}
                        <IconButton onClick={addDaysUntilCalculation} color="success">
                            <AddCircleOutlineIcon/>
                        </IconButton>
                    </Box>
                )}

                {tabIndex === 2 && <>TBD...</>}
                {tabIndex === 3 && (
                    <Box>
                        {basicCalculations.map((calc, index) => (
                            <Grid
                                container
                                spacing={2}
                                key={index}
                                sx={{marginBottom: 2}}
                                alignItems="center"
                            >
                                <Grid item xs={3}>
                                    <Select
                                        value={calc.column}
                                        onChange={(e) =>
                                            updateBasicCalculation(index, "column", e.target.value)
                                        }
                                        displayEmpty
                                        fullWidth
                                    >
                                        <MenuItem value="" disabled>
                                            Select Column
                                        </MenuItem>
                                        {props.nodeModel.getInputColumns().map((col) => (
                                            <MenuItem key={col} value={col}>
                                                {col}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </Grid>
                                <Grid item xs={2}>
                                    <Select
                                        value={calc.operation}
                                        onChange={(e) =>
                                            updateBasicCalculation(index, "operation", e.target.value)
                                        }
                                        displayEmpty
                                        fullWidth
                                    >
                                        <MenuItem value="" disabled>
                                            Select Operation
                                        </MenuItem>
                                        {operations.map((op) => (
                                            <MenuItem key={op} value={op}>
                                                {op}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </Grid>
                                <Grid item xs={3}>
                                    <Autocomplete
                                        freeSolo
                                        options={props.nodeModel.getInputColumns()}
                                        value={
                                            calc.value.type === "string"
                                                ? calc.value.value
                                                : calc.value.column
                                        }
                                        onInputChange={(event, newInputValue) => {
                                            updateBasicCalculation(index, "value", {
                                                type: "string",
                                                value: newInputValue || "",
                                            });
                                        }}
                                        onChange={(event, newValue) => {
                                            if (
                                                newValue &&
                                                props.nodeModel.getInputColumns().includes(newValue)
                                            ) {
                                                updateBasicCalculation(index, "value", {
                                                    type: "column",
                                                    column: newValue,
                                                });
                                            } else {
                                                updateBasicCalculation(index, "value", {
                                                    type: "string",
                                                    value: (newValue as string) || "",
                                                });
                                            }
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                placeholder="Select or type a value"
                                                fullWidth
                                            />
                                        )}
                                    />
                                </Grid>

                                <Grid item xs={3}>
                                    <TextField
                                        placeholder="New Column Name"
                                        value={calc.outputColumn}
                                        onChange={(e) =>
                                            updateBasicCalculation(
                                                index,
                                                "outputColumn",
                                                e.target.value
                                            )
                                        }
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={1}>
                                    <IconButton
                                        onClick={() => removeBasicCalculation(index)}
                                        color="error"
                                    >
                                        <RemoveCircleOutlineIcon/>
                                    </IconButton>
                                </Grid>
                            </Grid>
                        ))}
                        <IconButton onClick={addBasicCalculation} color="success">
                            <AddCircleOutlineIcon/>
                        </IconButton>
                    </Box>
                )}

                <Grid item xs={12} sx={{marginTop: '35px'}}>
                    <hr />
                </Grid>
                <Grid item xs={12} sx={{marginTop: '15px'}}>
                    <FormControl variant="outlined" fullWidth>
                        <TextField
                            label="Calculate Comment"
                            value={props.comment}
                            onChange={(e) => props.setComment(e.target.value)}
                            multiline
                            rows={2}
                        />
                    </FormControl>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={onApply}>Apply</Button>
            </DialogActions>
        </Dialog>
    );
};

export default CalculateNodeSettings;
