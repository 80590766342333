import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Select, TextField, Tooltip } from '@mui/material';
import { useState } from 'react';
import { useDataSourceClient } from '../../../../clients/DataSourceClient';
import { DataSourceType } from '../../../../clients/model/DataSource';

interface CreateDataSourceModalProps {
    open: boolean;
    onClose: () => void;
    onSubmit: () => void;
}

interface FormValues {
    name: string;
    type: DataSourceType;
    // FTP specific
    host?: string;
    port?: number;
    username?: string;
    password?: string;
    // S3 specific
    bucketName?: string;
    region?: string;
    accountId?: string;
}

export const CreateDataSourceModal = ({ open, onClose, onSubmit }: CreateDataSourceModalProps) => {
    const dataSourceClient = useDataSourceClient();
    const [values, setValues] = useState<FormValues>({
        name: '',
        type: DataSourceType.SPARKY
    });

    const handleSubmit = async () => {
        try {
            switch (values.type) {
                case DataSourceType.FTP:
                    await dataSourceClient.registerFTPDataSource({
                        name: values.name,
                        type: DataSourceType.FTP,
                        metadata: {
                            host: values.host!,
                            port: values.port!,
                            username: values.username!,
                            password: values.password
                        }
                    });
                    break;
                case DataSourceType.S3:
                    await dataSourceClient.registerS3DataSource({
                        name: values.name,
                        type: DataSourceType.S3,
                        metadata: {
                            bucketName: values.bucketName!,
                            region: values.region!,
                            accountId: values.accountId!
                        }
                    });
                    break;
                case DataSourceType.SPARKY:
                    await dataSourceClient.registerSparkyDataSource({
                        name: values.name,
                        type: DataSourceType.SPARKY
                    });
                    break;
            }
            onSubmit();
            onClose();
            setValues({ name: '', type: DataSourceType.SPARKY });
        } catch (error) {
            console.error('Failed to create datasource:', error);
        }
    };

    const isFormComplete = (): boolean => {
        if (!values.name.trim()) return false;

        switch (values.type) {
            case DataSourceType.FTP:
                return !!(
                    values.host?.trim() &&
                    values.port &&
                    values.username?.trim() &&
                    values.password?.trim()
                );
            case DataSourceType.S3:
                return !!(
                    values.bucketName?.trim() &&
                    values.region?.trim() &&
                    values.accountId?.trim()
                );
            case DataSourceType.SPARKY:
                return true;
            default:
                return false;
        }
    };

    const getTooltipMessage = (): string => {
        if (!values.name.trim()) return "Please enter a name";

        switch (values.type) {
            case DataSourceType.FTP:
                if (!values.host?.trim()) return "Please enter a host";
                if (!values.port) return "Please enter a port";
                if (!values.username?.trim()) return "Please enter a username";
                if (!values.password?.trim()) return "Please enter a password";
                break;
            case DataSourceType.S3:
                if (!values.bucketName?.trim()) return "Please enter a bucket name";
                if (!values.region?.trim()) return "Please enter a region";
                if (!values.accountId?.trim()) return "Please enter an account ID";
                break;
        }
        return "";
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Create New Datasource</DialogTitle>
            <DialogContent>
                <FormControl fullWidth margin="dense" sx={{ mt: 2, mb: 2 }}>
                    <InputLabel>Type</InputLabel>
                    <Select
                        value={values.type}
                        label="Type"
                        onChange={(e) => setValues({ ...values, type: e.target.value as DataSourceType })}
                    >
                        <MenuItem value={DataSourceType.SPARKY}>Sparky</MenuItem>
                        <MenuItem value={DataSourceType.FTP}>FTP</MenuItem>
                        <MenuItem value={DataSourceType.S3}>S3</MenuItem>
                    </Select>
                </FormControl>

                <TextField
                    margin="dense"
                    label="Name"
                    fullWidth
                    required
                    value={values.name}
                    onChange={(e) => setValues({ ...values, name: e.target.value })}
                    sx={{ mt: 2, mb: 1 }}
                />

                {values.type === DataSourceType.FTP && (
                    <>
                        <TextField
                            margin="dense"
                            label="Host"
                            fullWidth
                            required
                            value={values.host}
                            onChange={(e) => setValues({ ...values, host: e.target.value })}
                            sx={{ mt: 2, mb: 1 }}
                        />
                        <TextField
                            margin="dense"
                            label="Port"
                            type="number"
                            fullWidth
                            required
                            value={values.port}
                            onChange={(e) => setValues({ ...values, port: parseInt(e.target.value) })}
                            sx={{ mt: 2, mb: 1 }}
                        />
                        <TextField
                            margin="dense"
                            label="Username"
                            fullWidth
                            required
                            value={values.username}
                            onChange={(e) => setValues({ ...values, username: e.target.value })}
                            sx={{ mt: 2, mb: 1 }}
                        />
                        <TextField
                            margin="dense"
                            label="Password"
                            type="password"
                            fullWidth
                            required
                            value={values.password}
                            onChange={(e) => setValues({ ...values, password: e.target.value })}
                            sx={{ mt: 2, mb: 1 }}
                        />
                    </>
                )}

                {values.type === DataSourceType.S3 && (
                    <>
                        <TextField
                            margin="dense"
                            label="Bucket Name"
                            fullWidth
                            required
                            value={values.bucketName}
                            onChange={(e) => setValues({ ...values, bucketName: e.target.value })}
                            sx={{ mt: 2, mb: 1 }}
                        />
                        <TextField
                            margin="dense"
                            label="Region"
                            fullWidth
                            required
                            value={values.region}
                            onChange={(e) => setValues({ ...values, region: e.target.value })}
                            sx={{ mt: 2, mb: 1 }}
                        />
                        <TextField
                            margin="dense"
                            label="Account ID"
                            fullWidth
                            required
                            value={values.accountId}
                            onChange={(e) => setValues({ ...values, accountId: e.target.value })}
                            sx={{ mt: 2, mb: 1 }}
                        />
                    </>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Tooltip 
                    title={getTooltipMessage()} 
                >
                    <span>
                        <Button 
                            onClick={handleSubmit} 
                            variant="contained" 
                            color="primary"
                            disabled={!isFormComplete()}
                        >
                            Create
                        </Button>
                    </span>
                </Tooltip>
            </DialogActions>
        </Dialog>
    );
}; 