import { Button, Grid, Typography } from '@mui/material';
import * as React from 'react';
import WhatsHot from '@mui/icons-material/Whatshot'
import { NavLink } from "react-router-dom";
import Logo from '../../components/common/Logo';
import LoginIcon from "@mui/icons-material/Login";

export interface IHomeProps {
}

const Home: React.FunctionComponent<IHomeProps> = (props: IHomeProps) => {
    return (
        <>
            <Grid container spacing={3} justifyContent="center" sx={{paddingTop: "100px"}}>
                <Grid item xs={3}>
                    <Logo sx={{width: "100%", height: "100%" }} />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h2" align="center">
                        Sparky mag FeuFeu!
                    </Typography>
                    <br/>
                    <Typography variant="h6" align="center">
                        Probier mich aus!
                    </Typography>
                </Grid>
                <Grid item xs={3} container justifyContent="center">
                    <Button component={NavLink} to="/demo" variant='outlined' startIcon={<WhatsHot />}>
                        Demo
                    </Button>
                    &nbsp;
                    &nbsp;
                    <Button component={NavLink} to="/login" variant='contained' startIcon={<LoginIcon />}>
                        Login
                    </Button>
                </Grid>
            </Grid>
        </>
    );
}

export default Home;