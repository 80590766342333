import {PortModelAlignment} from '@projectstorm/react-diagrams';
import {SparkyELTPortModel} from '../../port/SparkyELTPortModel';
import {SparkyBasicNodeModel} from "../SparkyBasicNodeModel";

export class WriteNodeModel extends SparkyBasicNodeModel {

    selectedConnectionId: string;
    selectedTargetElement: string;

    constructor() {
        super('Node_WRITE');
        this.addPort(new SparkyELTPortModel(PortModelAlignment.LEFT, 'input'));

        this.selectedConnectionId = "";
        this.selectedTargetElement = "";

    }

    // called during stream resonstruction from file
    calculateOutputCols(): string[] {
        return []
    }

    serialize(): any {
        return {
            ...super.serialize(),
            comment: this.comment,

            selectedConnectionId: this.selectedConnectionId,
            selectedTargetElement: this.selectedTargetElement,

        };
    }

    deserialize(event: any): void {
        super.deserialize(event);
        this.comment = event.data.comment;

        this.selectedConnectionId = event.data.selectedConnectionId;
        this.selectedTargetElement = event.data.selectedTargetElement;

        this.settingsSummary = this.calcSettingsSummary()
    }

    getSelectedConnectionId(): string {
        return this.selectedConnectionId;
    }

    setSelectedConnectionId(value: string): void {
        this.selectedConnectionId = value;
    }

    getSelectedTargetElement(): string {
        return this.selectedTargetElement;
    }

    setSelectedTargetElement(value: string): void {
        this.selectedTargetElement = value;
    }

    calcSettingsSummary() {
        const tmp = this.getSelectedTargetElement().split("/")
        return tmp[tmp.length - 1];
    }
}