import React, {useState} from "react";
import {
    Dialog, DialogActions, DialogContent, Button, FormControl, TextField, MenuItem, Grid, IconButton,
} from "@mui/material";
import DialogHeader from "../../../../../shared/components/dialogs/DialogHeader";
import {GroupByNodeModel} from "../GroupByNodeModel";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

interface GroupByNodeSettingsProps {
    open: boolean;
    handleClose: () => void;
    nodeModel: GroupByNodeModel;
    comment: string;
    setComment: React.Dispatch<React.SetStateAction<string>>;
    setSettingsSummary: React.Dispatch<React.SetStateAction<string>>;
}

const predefinedOperations = [
    "Sum",
    "Count",
    "Minimum",
    "Maximum",
    "Average",
    "Concat",
];

const GroupByNodeSettings: React.FC<GroupByNodeSettingsProps> = (props: GroupByNodeSettingsProps) => {
    const [groupByCols, setGroupByCols] = useState<string[]>([""]);
    const [operationColumn, setOperationColumn] = useState<string>("");
    const [groupOperation, setGroupOperation] = useState<string>("");
    const [newColName, setNewColName] = useState<string>("");
    const [delimiter, setDelimiter] = useState<string>("");

    const onClose = () => {
        props.handleClose && props.handleClose();
    };

    const onApply = () => {
        
        // --
        props.nodeModel.setGroupedByColumns(groupByCols);
        props.nodeModel.setOperationColumn(operationColumn);
        props.nodeModel.setGroupOperation(groupOperation);
        props.nodeModel.setConcatDelimiter(delimiter);
        props.nodeModel.setNewColName(newColName);
        // --

        props.nodeModel.setOutputColumns(props.nodeModel.calculateOutputCols());
        props.nodeModel.setComment(props.comment);
        props.nodeModel.setSettingsSummary(props.nodeModel.calcSettingsSummary());
        props.setSettingsSummary(props.nodeModel.getSettingsSummary())

        onClose();
    };

    // used to re-load the filter-settings after the stream was reconstructed from file
    React.useEffect(() => {
        props.setComment(props.nodeModel.comment)
        // eslint-disable-next-line
    }, [props.nodeModel.comment])
    React.useEffect(() => {
        setGroupByCols(props.nodeModel.groupedByColumns)
        // eslint-disable-next-line
    }, [props.nodeModel.groupedByColumns])
    React.useEffect(() => {
        setOperationColumn(props.nodeModel.operationColumn)
        // eslint-disable-next-line
    }, [props.nodeModel.operationColumn])
    React.useEffect(() => {
        setGroupOperation(props.nodeModel.groupOperation)
        // eslint-disable-next-line
    }, [props.nodeModel.groupOperation])
    React.useEffect(() => {
        setNewColName(props.nodeModel.newColName)
        // eslint-disable-next-line
    }, [props.nodeModel.newColName])
    React.useEffect(() => {
        setDelimiter(props.nodeModel.concatDelimiter)
        // eslint-disable-next-line
    }, [props.nodeModel.concatDelimiter])

    const handleAddGroupByCol = () => {
        setGroupByCols([...groupByCols, ""]);
    };

    const handleRemoveGroupByCol = (index: number) => {
        const updatedCols = groupByCols.filter((_, i) => i !== index);
        setGroupByCols(updatedCols);
    };

    const handleGroupByColChange = (value: string, index: number) => {
        const updatedCols = [...groupByCols];
        updatedCols[index] = value;
        setGroupByCols(updatedCols);
    };

    return (
        <Dialog
            open={props.open}
            onClose={onClose}
            maxWidth="lg"
            scroll="paper"
            PaperProps={{sx: {backgroundColor: "white"}}}
            disableEnforceFocus
            fullWidth
        >
            <DialogHeader
                headline={"Group By Column(s)"}
                closeDialog={onClose}
            />
            <DialogContent>
                <Grid container sx={{marginTop: '10px'}} spacing={1}>
                    {groupByCols.map((groupByCol, index) => (
                        <>
                            <Grid item xs={11} key={index + "_col"}>
                                <FormControl fullWidth>
                                    <TextField
                                        select
                                        label={`Group by Column ${index + 1}`}
                                        value={groupByCol}
                                        onChange={(e) => handleGroupByColChange(e.target.value, index)}
                                    >
                                        {props.nodeModel.inputColumns.map((col, idx) => (
                                            <MenuItem key={idx} value={col}>
                                                {col}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </FormControl>
                            </Grid>
                            <Grid item xs={1} key={index + "_rem"}
                                  sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                <IconButton onClick={() => handleRemoveGroupByCol(index)} color="error">
                                    <RemoveCircleOutlineIcon/>
                                </IconButton>
                            </Grid>

                        </>
                    ))}

                    <Grid item xs={12} sx={{marginTop: '10px', marginBottom: '10px'}}>
                        <IconButton onClick={handleAddGroupByCol} color="success">
                            <AddCircleOutlineIcon/>
                        </IconButton>
                    </Grid>

                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <TextField
                                select
                                label="Operation"
                                value={groupOperation}
                                onChange={(e) => setGroupOperation(e.target.value)}
                            >
                                {predefinedOperations.map((val, idx) => (
                                    <MenuItem key={idx} value={val}>
                                        {val}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </FormControl>
                    </Grid>

                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <TextField
                                select
                                label="On Column"
                                value={operationColumn}
                                onChange={(e) => setOperationColumn(e.target.value)}
                            >
                                {props.nodeModel.inputColumns.map((col, idx) => (
                                    <MenuItem key={idx} value={col}>
                                        {col}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </FormControl>
                    </Grid>
                    {groupOperation === "Concat" && (
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <TextField
                                    label="Delimiter"
                                    value={delimiter}
                                    error={
                                        delimiter.includes(",") ||
                                        delimiter.includes(";") ||
                                        delimiter.includes("/") ||
                                        delimiter.includes("|")
                                    }
                                    helperText={
                                        delimiter.includes(",") ||
                                        delimiter.includes(";") ||
                                        delimiter.includes("/") ||
                                        delimiter.includes("|")
                                            ? "Delimiter may not include , ; / |"
                                            : ""
                                    }
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        setDelimiter(value);
                                    }}
                                />
                            </FormControl>
                        </Grid>
                    )}

                    <Grid item xs={12} sx={{marginTop: '25px'}}>
                        <FormControl fullWidth>
                            <TextField
                                label="New column name"
                                value={newColName}
                                onChange={(e) => setNewColName(e.target.value)}
                            />
                        </FormControl>
                    </Grid>
                </Grid>

                <Grid item xs={12} sx={{marginTop: '35px'}}>
                    <hr/>
                </Grid>
                <Grid item xs={12} sx={{marginTop: '15px'}}>
                    <FormControl fullWidth>
                        <TextField
                            label="Node Comment"
                            value={props.comment}
                            onChange={(e) => props.setComment(e.target.value)}
                            multiline
                            rows={2}
                        />
                    </FormControl>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={onApply}>Apply</Button>
            </DialogActions>
        </Dialog>
    );
};

export default GroupByNodeSettings;
