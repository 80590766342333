import React, {useState} from "react";
import {FilterNodeModel} from "./FilterNodeModel";
import {
    DiagramEngine,
    PortModelAlignment,
    PortWidget,
} from "@projectstorm/react-diagrams";
import {SparkyELTPortModel} from "../../port/SparkyELTPortModel";
import {S} from "../../port/SimplePortWidget";
import {Typography} from "@mui/material";
import FilterNodeSettings from "./settings/FilterNodeSettings";
import DefaultNodeDecoration, {getPrimaryColorByNodeType} from "../DefaultNodeDecoration";
import {NodeTypeEnum} from "../NodeTypeEnum";
import {useStreamContext} from "../../../../contexts/StreamContext";
import {SparkyNodeStatusInfo} from "../SparkyBasicNodeModel";

export interface FilterNodeWidgetProps {
    node: FilterNodeModel;
    engine: DiagramEngine;
    width: number;
    height: number;
}

const FilterNodeWidget: React.FC<FilterNodeWidgetProps> = (props) => {
    
    const {selectedNodeIds, selectNodeIds} = useStreamContext();

    const [settingsSummary, setSettingsSummary] = React.useState<string>(props.node.getSettingsSummary());
    const [modalOpen, setModalOpen] = useState(false);
    const [comment, setComment] = useState(props.node.getComment());
    const [highlighted, setHighlighted] = React.useState<boolean>(false);

    const handleOpen = () => {
        setModalOpen(true);
    };

    const handleClose = () => {
        setModalOpen(false);
    };

    const handleOnNodeClick = (event: React.MouseEvent<HTMLDivElement>) => {
        event.stopPropagation(); // Stop propagation to allow dragging
        if (event.shiftKey) { // multi selection
            if (selectedNodeIds.indexOf(props.node.getID()) > -1) {
                // remove from selected elements
                selectNodeIds(selectedNodeIds.filter(item => item !== props.node.getID()));
            } else {
                selectNodeIds([...selectedNodeIds, props.node.getID()])
            }
        } else { // single selection
            // selectNodeIds([props.node.getID()])
            if (selectedNodeIds.indexOf(props.node.getID()) > -1) {
                // selectNodeIds([])
            } else {
                selectNodeIds([props.node.getID()])
            }
            console.log("Shift not pressed " + props.node.getID())
        }
    }

    const [numLinesOut, setNumLinesOut] = React.useState<number | undefined>(undefined);
    const [numLinesOut2, setNumLinesOut2] = React.useState<number | undefined>(undefined);
    React.useEffect(() => {
        const listener = props.node.registerListener({
            eventDidFire: (event: any) => {
                if (event.function === 'statusInfoUpdated') {
                    const info = event.statusInfo as SparkyNodeStatusInfo
                    if (info.count >= 0) {
                        if (info.extraInfo === "not") {
                            setNumLinesOut2(info.count)
                        } else {
                            setNumLinesOut(info.count)
                        }
                    }
                }
            },
        });
        return () => { listener.deregister(); };
    }, [props.node]);

    React.useEffect(() => {
        if (props.node.isSelected()) {
            setHighlighted(true)
        } else {
            setHighlighted(false)
        }
        // eslint-disable-next-line
    }, [props.node.isSelected()])

    return (
        <div
            className="diamond-node"
            style={{
                position: "relative",
                width: props.width,
                height: props.height,
            }}
            onDoubleClick={handleOpen}
            onClick={handleOnNodeClick}
        >
            <svg
                width="303"
                height="178"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                overflow="hidden"
            >
                <defs>
                    <filter
                        id="fx0-nodeFilter"
                        x="-10%"
                        y="-10%"
                        width="120%"
                        height="120%"
                        filterUnits="userSpaceOnUse"
                        primitiveUnits="userSpaceOnUse"
                    >
                        <feComponentTransfer colorInterpolationFilters="sRGB">
                            <feFuncR type="discrete" tableValues="0 0"/>
                            <feFuncG type="discrete" tableValues="0 0"/>
                            <feFuncB type="discrete" tableValues="0 0"/>
                            <feFuncA type="linear" slope="0.4" intercept="0"/>
                        </feComponentTransfer>
                        <feGaussianBlur stdDeviation="1.77778 1.77778"/>
                    </filter>
                    <clipPath id="clip1-nodeFilter">
                        <rect x="703" y="276" width="303" height="178"/>
                    </clipPath>
                    <clipPath id="clip2-nodeFilter">
                        <path
                            d="M736.507 283C721.952 283 710.152 294.79 710.152 309.334L710.152 381.883 710.152 392.4 710 392.4 710 414.666C710 429.21 721.8 441 736.355 441L966.493 441C981.048 441 992.848 429.21 992.848 414.666L992.848 392.4 905.769 392.4 905.769 381.883 993 381.883 993 309.334C993 294.79 981.2 283 966.645 283L905.769 283ZM703 276 1006 276 1006 454 703 454Z"
                            fillRule="evenodd"
                            clipRule="evenodd"
                        />
                    </clipPath>
                </defs>
                <g clipPath="url(#clip1-nodeFilter)" transform="translate(-703 -276)">
                    <g clipPath="url(#clip2-nodeFilter)">
                        <g filter="url(#fx0-nodeFilter)" transform="translate(702 275)">
                            <g>
                                <path
                                    d="M37.3355 10.8284 206.597 10.8284 267.473 10.8284C282.029 10.8284 293.828 22.6185 293.828 37.1622L293.828 109.711 206.597 109.711 206.597 120.229 293.676 120.229 293.676 142.495C293.676 157.038 281.877 168.828 267.321 168.828L37.1835 168.828C22.628 168.828 10.8284 157.038 10.8284 142.495L10.8284 120.229 10.9804 120.229 10.9804 109.711 10.9804 37.1622C10.9804 22.6185 22.78 10.8284 37.3355 10.8284Z"
                                    stroke={highlighted ? "#333333" : "#FFFFFF"}
                                    strokeWidth="8"
                                    strokeMiterlimit="8"
                                    fill={getPrimaryColorByNodeType(NodeTypeEnum.NODE_FILTER)}
                                    fillRule="evenodd"
                                />
                            </g>
                        </g>
                    </g>
                    <path
                        d="M736.507 283 905.769 283 966.645 283C981.2 283 993 294.79 993 309.334L993 381.883 905.769 381.883 905.769 392.4 992.848 392.4 992.848 414.666C992.848 429.21 981.048 441 966.493 441L736.355 441C721.8 441 710 429.21 710 414.666L710 392.4 710.152 392.4 710.152 381.883 710.152 309.334C710.152 294.79 721.952 283 736.507 283Z"
                        stroke={highlighted ? "#333333" : "#FFFFFF"}
                        strokeWidth="8"
                        strokeMiterlimit="8"
                        fill={getPrimaryColorByNodeType(NodeTypeEnum.NODE_FILTER)}
                        fillRule="evenodd"
                    />
                </g>
            </svg>

            <DefaultNodeDecoration
                nodeType={NodeTypeEnum.NODE_FILTER}
                comment={comment}
                textOnNode={settingsSummary}
                onDoubleClick={handleOpen}
                numLinesOut={numLinesOut}
                numLinesOut2={numLinesOut2}
            />

            <Typography
                align="center"
                textAlign="center"
                sx={{
                    position: "absolute",
                    top: 20,
                    left: 110,
                    width: 160,
                    backgroundColor: "rgba(255,255,255,0.5)",
                    borderRadius: "15px",
                    padding: "5px",
                    maxHeight: "70px",
                    overflowY: "auto",
                }}
            >
                Filtered Conditions:
                <br/>
                {props.node.getFilteredConditions().map((filter, index) => (
                    <div key={index} style={{marginBottom: "2px"}}>
                        {filter}
                    </div>
                ))}
            </Typography>
            <Typography
                align="center"
                textAlign="center"
                sx={{
                    position: "absolute",
                    top: 130,
                    left: 110,
                    width: 160,
                    backgroundColor: "rgba(255,255,255,0.5)",
                    borderRadius: "15px",
                }}
            >
                Filtered Out Values:
                <br/>
                {props.node.getSkippedConditions().map((filter, index) => (
                    <div key={index} style={{marginBottom: "2px"}}>
                        {filter}
                    </div>
                ))}
            </Typography>

            <FilterNodeSettings
                open={modalOpen}
                handleClose={handleClose}
                nodeModel={props.node}
                comment={comment}
                setComment={(newComment) => {
                    if (typeof newComment === "function") {
                        const commentValue = newComment(comment);
                        setComment(commentValue);
                        props.node.setComment(commentValue);
                    } else {
                        setComment(newComment);
                        props.node.setComment(newComment);
                    }
                }}
                setSettingsSummary={setSettingsSummary}
            />

            <PortWidget
                style={{
                    top: props.height / 2 - 22,
                    left: -25,
                    position: "absolute",
                }}
                port={
                    props.node.getPort("input") ||
                    new SparkyELTPortModel(PortModelAlignment.LEFT, "input")
                }
                engine={props.engine}
            >
                <S.Port/>
            </PortWidget>
            <PortWidget
                style={{
                    top: props.height / 3 - 22,
                    left: props.width - 16,
                    position: "absolute",
                }}
                port={
                    props.node.getPort("outputTop") ||
                    new SparkyELTPortModel(PortModelAlignment.RIGHT, "outputTop")
                }
                engine={props.engine}
            >
                <S.Port/>
            </PortWidget>

            <PortWidget
                style={{
                    top: (2 * props.height) / 3 - 22,
                    left: props.width - 16,
                    position: "absolute",
                }}
                port={
                    props.node.getPort("outputBottom") ||
                    new SparkyELTPortModel(PortModelAlignment.RIGHT, "outputBottom")
                }
                engine={props.engine}
            >
                <S.Port/>
            </PortWidget>
        </div>
    );
};

export default FilterNodeWidget;
