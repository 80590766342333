import {PortModelAlignment} from '@projectstorm/react-diagrams';
import {SparkyELTPortModel} from '../../port/SparkyELTPortModel';
import {SparkyBasicNodeModel} from "../SparkyBasicNodeModel";

export class ReorderColsNodeModel extends SparkyBasicNodeModel {
    constructor() {
        super('Node_REORDER_COLS');
        this.addPort(new SparkyELTPortModel(PortModelAlignment.LEFT, 'input'));
        this.addPort(new SparkyELTPortModel(PortModelAlignment.RIGHT, 'output'));
    }
}