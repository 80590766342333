import React, {useState} from "react";
import {
    Dialog, DialogActions, DialogContent,
    Button, FormControl, TextField, Grid, MenuItem, IconButton,
} from "@mui/material";
import DialogHeader from "../../../../../shared/components/dialogs/DialogHeader";
import {SortNodeModel} from "../SortNodeModel";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import {BsSortDownAlt, BsSortUp} from "react-icons/bs";

interface SortNodeSettingsProps {
    open: boolean;
    handleClose: () => void;
    nodeModel: SortNodeModel;
    comment: string;
    setComment: React.Dispatch<React.SetStateAction<string>>;
    setSettingsSummary: React.Dispatch<React.SetStateAction<string>>;
}

const SortNodeSettings: React.FC<SortNodeSettingsProps> = (
    props: SortNodeSettingsProps
) => {
    const [sortCols, setSortCols] = useState<string[]>([""]);
    const [sortColsDesc, setSortColsDesc] = useState<boolean[]>([false]);

    const onClose = () => {
        props.handleClose && props.handleClose();
    };

    const onApply = () => {

        // --
        props.nodeModel.setSortColumns(sortCols)
        props.nodeModel.setSortDesc(sortColsDesc)
        // --

        props.nodeModel.setOutputColumns(props.nodeModel.calculateOutputCols());
        props.nodeModel.setComment(props.comment);
        props.nodeModel.setSettingsSummary(props.nodeModel.calcSettingsSummary());
        props.setSettingsSummary(props.nodeModel.getSettingsSummary())

        onClose();
    };

    // dont propagate events to componente below - tbd
    /*
    React.useEffect(() => {
        if (!props.open) return;
        const dialogContent = dialogContentRef.current;
        const preventScroll = (e: WheelEvent) => {
            // e.preventDefault();
            e.stopPropagation();
        };

        const attachEventListener = () => {
            if (dialogContent) {
                dialogContent.addEventListener("wheel", preventScroll);
            }
        };

        if (props.open) {
            // Use setTimeout to ensure the dialog content is rendered before attaching the event listener
            setTimeout(attachEventListener, 0);
        }

        return () => {
            if (dialogContent) {
                dialogContent.removeEventListener("wheel", preventScroll);
            }
        };
    }, [props.open, dialogContentRef]);
     */

    // used to re-load the filter-settings after the stream was reconstructed from file
    React.useEffect(() => {
        props.setComment(props.nodeModel.comment)
        // eslint-disable-next-line
    }, [props.nodeModel.comment])
    React.useEffect(() => {
        setSortCols(props.nodeModel.sortColumns)
        // eslint-disable-next-line
    }, [props.nodeModel.sortColumns])
    React.useEffect(() => {
        setSortColsDesc(props.nodeModel.sortDesc)
        // eslint-disable-next-line
    }, [props.nodeModel.sortDesc])


    const handleAddSortCol = () => {
        setSortCols([...sortCols, ""]);
        setSortColsDesc([...sortColsDesc, false]);
    };

    const handleRemoveSortCol = (index: number) => {
        const updatedSortCols = sortCols.filter((_, i) => i !== index);
        const updatedSortColsDes = sortColsDesc.filter((_, i) => i !== index);
        setSortCols(updatedSortCols);
        setSortColsDesc(updatedSortColsDes);
    };

    const handleSortColChange = (value: string, index: number) => {
        const updatedSortCols = [...sortCols];
        updatedSortCols[index] = value;
        setSortCols(updatedSortCols);
    };

    const toggleSortOrderChange = (index: number) => {
        const updatedSortColsDes = [...sortColsDesc];
        updatedSortColsDes[index] = !updatedSortColsDes[index];
        setSortColsDesc(updatedSortColsDes);
    };


    return (
        <Dialog
            open={props.open}
            onClose={onClose}
            maxWidth="lg"
            scroll="paper"
            PaperProps={{sx: {backgroundColor: "white"}}}
            disableEnforceFocus
            fullWidth
        >
            <DialogHeader headline="Sort Data by Column(s)" closeDialog={onClose}/>
            <DialogContent>
                <Grid container sx={{marginTop: '10px'}} spacing={1}>
                    {sortCols.map((sortCol, index) => (
                        <>
                            <Grid item xs={11} key={index + "_col"}>
                                <FormControl fullWidth>
                                    <TextField
                                        select
                                        label={`Sort by Column ${index + 1}`}
                                        value={sortCol}
                                        onChange={(e) => handleSortColChange(e.target.value, index)}
                                    >
                                        {props.nodeModel.inputColumns.map((col, idx) => (
                                            <MenuItem key={index + "_" + idx} value={col}>
                                                {col}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </FormControl>
                            </Grid>
                            <Grid item xs={0.5} key={index + "_descasc"}
                                  sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                <IconButton onClick={() => toggleSortOrderChange(index)}>
                                    {!sortColsDesc[index] ? <BsSortDownAlt/> : <BsSortUp/>}
                                </IconButton>
                            </Grid>
                            <Grid item xs={0.5} key={index + "_rem"}
                                  sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                <IconButton onClick={() => handleRemoveSortCol(index)} color="error">
                                    <RemoveCircleOutlineIcon/>
                                </IconButton>
                            </Grid>

                        </>
                    ))}
                </Grid>
                <Grid item xs={12} sx={{marginTop: '10px', marginBottom: '25px'}}>
                    <IconButton onClick={handleAddSortCol} color="success">
                        <AddCircleOutlineIcon/>
                    </IconButton>
                </Grid>
                <Grid item xs={12} sx={{marginTop: '35px'}}>
                    <hr />
                </Grid>
                <Grid item xs={12} sx={{marginTop: '15px'}}>
                    <FormControl variant="outlined" fullWidth>
                        <TextField
                            label="Node Comment"
                            value={props.comment}
                            onChange={(e) => props.setComment(e.target.value)}
                            multiline
                            rows={2}
                        />
                    </FormControl>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={onApply}>Apply</Button>
            </DialogActions>
        </Dialog>
    );
};

export default SortNodeSettings;
