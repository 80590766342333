import * as React from 'react';
import { Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';
import ClusterConfig from '../ClusterConfig';

export interface IManageClusterModalProps {
    open: boolean;
    onClose: () => void;
}

const ManageClusterModal: React.FunctionComponent<IManageClusterModalProps> = (props: IManageClusterModalProps) => {
    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            maxWidth="lg"
            fullWidth
        >
            <DialogTitle sx={{ m: 0, p: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                Manage Clusters
                <IconButton
                    onClick={props.onClose}
                    size="small"
                >
                    <Close />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <ClusterConfig />
            </DialogContent>
        </Dialog>
    );
}

export default ManageClusterModal; 