import {
    PortModelAlignment,
} from "@projectstorm/react-diagrams";
import {SparkyELTPortModel} from "../../port/SparkyELTPortModel";
import {SparkyBasicNodeModel} from "../SparkyBasicNodeModel";

export class SortNodeModel extends SparkyBasicNodeModel {
    
    sortColumns: string[];
    sortDesc: boolean[];

    constructor() {
        super("Node_SORT");
        this.addPort(new SparkyELTPortModel(PortModelAlignment.LEFT, "input"));
        this.addPort(new SparkyELTPortModel(PortModelAlignment.RIGHT, "output"));

        this.sortColumns = [""];
        this.sortDesc = [false];
    }

    // called during stream resonstruction from file
    calculateOutputCols(): string[] {
        this.setOutputColumns(this.getInputColumns())
        return this.getOutputColumns()
    }

    serialize(): any {
        return {
            ...super.serialize(),
            comment: this.comment,

            sortColumns: this.sortColumns,
            sortDesc: this.sortDesc
        };
    }

    deserialize(event: any): void {
        super.deserialize(event)
        this.comment = event.data.comment

        this.sortColumns = event.data.sortColumns
        this.sortDesc = event.data.sortDesc

        this.settingsSummary = this.calcSettingsSummary()
    }

    getSortColumns(): string[] {
        return this.sortColumns;
    }

    setSortColumns(cols: string[]): void {
        this.sortColumns = cols;
    }

    getSortDesc(): boolean[] {
        return this.sortDesc;
    }

    setSortDesc(cols: boolean[]): void {
        this.sortDesc = cols;
    }

    calcSettingsSummary() {
        let settingsSummary = ""
        for (let i = 0; i < this.sortColumns.length; i++) {
            if (this.sortColumns[i].length === 0) {
                continue
            }
            settingsSummary += this.sortColumns[i] + "" + (this.sortDesc[i] ? "↑" : "↓") + ", "
        }
        if (settingsSummary.length > 0) {
            settingsSummary = "by " + settingsSummary
        }
        return settingsSummary;
    }
}
