import * as React from 'react';
import {AppendNodeModel} from './AppendNodeModel';
import {DiagramEngine, PortModelAlignment, PortWidget} from '@projectstorm/react-diagrams';
import {SparkyELTPortModel} from "../../port/SparkyELTPortModel";
import { S } from '../../port/SimplePortWidget';
import {NodeTypeEnum} from "../NodeTypeEnum";
import DefaultNodeDecoration, {getPrimaryColorByNodeType} from "../DefaultNodeDecoration";

export interface AppendNodeWidgetProps {
    node: AppendNodeModel;
    engine: DiagramEngine;
    width: number;
    height: number;
}

export class AppendNodeWidget extends React.Component<AppendNodeWidgetProps> {
    render() {
        return (
            <div
                className={'diamond-node'}
                style={{
                    position: 'relative',
                    width: this.props.width,
                    height: this.props.height
                }}
            >
                <svg width="304" height="179" xmlns="http://www.w3.org/2000/svg"
                     xmlnsXlink="http://www.w3.org/1999/xlink" overflow="hidden">
                    <defs>
                        <filter id="fx0-nodeAppend" x="-10%" y="-10%" width="120%" height="120%" filterUnits="userSpaceOnUse"
                                primitiveUnits="userSpaceOnUse">
                            <feComponentTransfer colorInterpolationFilters="sRGB">
                                <feFuncR type="discrete" tableValues="0 0"/>
                                <feFuncG type="discrete" tableValues="0 0"/>
                                <feFuncB type="discrete" tableValues="0 0"/>
                                <feFuncA type="linear" slope="0.4" intercept="0"/>
                            </feComponentTransfer>
                            <feGaussianBlur stdDeviation="1.77778 1.77778"/>
                        </filter>
                        <clipPath id="clip1-nodeAppend">
                            <rect x="290" y="510" width="304" height="179"/>
                        </clipPath>
                        <clipPath id="clip2-nodeAppend">
                            <path
                                d="M323.448 518C308.841 518 297 529.79 297 544.334L297 616.883 384.539 616.883 384.539 627.4 297.152 627.4 297.152 649.666C297.152 664.21 308.994 676 323.601 676L554.552 676C569.159 676 581 664.21 581 649.666L581 627.4 580.847 627.4 580.847 616.883 580.847 544.334C580.847 529.79 569.006 518 554.399 518L384.539 518ZM290 510 594 510 594 689 290 689Z"
                                fillRule="evenodd" clipRule="evenodd"/>
                        </clipPath>
                    </defs>
                    <g clipPath="url(#clip1-nodeAppend)" transform="translate(-290 -510)">
                        <g clipPath="url(#clip2-nodeAppend)">
                            <g filter="url(#fx0-nodeAppend)" transform="translate(289 510)">
                                <g>
                                    <path
                                        d="M26.6007 0 196.461 0 257.552 0C272.159 0 284 11.79 284 26.3338L284 98.883 196.461 98.883 196.461 109.4 283.848 109.4 283.848 131.666C283.848 146.21 272.006 158 257.399 158L26.4482 158C11.8413 158 0 146.21 0 131.666L0 109.4 0.152504 109.4 0.152504 98.883 0.152504 26.3338C0.152504 11.79 11.9938 0 26.6007 0Z"
                                        stroke="#FFFFFF" strokeWidth="8" strokeMiterlimit="8" fill={getPrimaryColorByNodeType(NodeTypeEnum.NODE_APPEND)}
                                        fillRule="evenodd" transform="matrix(-1 0 0 1 294.828 10.8284)"/>
                                </g>
                            </g>
                        </g>
                        <path
                            d="M26.6007 0 196.461 0 257.552 0C272.159 0 284 11.79 284 26.3338L284 98.883 196.461 98.883 196.461 109.4 283.848 109.4 283.848 131.666C283.848 146.21 272.006 158 257.399 158L26.4482 158C11.8413 158 0 146.21 0 131.666L0 109.4 0.152504 109.4 0.152504 98.883 0.152504 26.3338C0.152504 11.79 11.9938 0 26.6007 0Z"
                            stroke="#FFFFFF" strokeWidth="8" strokeMiterlimit="8" fill={getPrimaryColorByNodeType(NodeTypeEnum.NODE_APPEND)} fillRule="evenodd"
                            transform="matrix(-1 0 0 1 581 518)"/>
                    </g>
                </svg>

                <DefaultNodeDecoration
                    nodeType={NodeTypeEnum.NODE_APPEND}
                    comment={""}
                    textOnNode={"Both Files"}
                />

                <PortWidget
                    style={{
                        top: this.props.height / 3 - 22,
                        left: - 25,
                        position: 'absolute'
                    }}
                    port={this.props.node.getPort('inputTop') || new SparkyELTPortModel(PortModelAlignment.LEFT, 'inputTop')}
                    engine={this.props.engine}
                >
                    <S.Port/>
                </PortWidget>
                <PortWidget
                    style={{
                        top: 2 * this.props.height / 3 - 22,
                        left: - 25,
                        position: 'absolute'
                    }}
                    port={this.props.node.getPort('inputBottom') || new SparkyELTPortModel(PortModelAlignment.LEFT, 'inputBottom')}
                    engine={this.props.engine}
                >
                    <S.Port/>
                </PortWidget>

                <PortWidget
                    style={{
                        top: this.props.height / 2 - 22,
                        left: this.props.width - 14,
                        position: 'absolute'
                    }}
                    port={this.props.node.getPort('output') || new SparkyELTPortModel(PortModelAlignment.RIGHT, 'output')}
                    engine={this.props.engine}
                >
                    <S.Port/>
                </PortWidget>

            </div>
        );
    }
}