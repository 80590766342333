import * as React from 'react';
import {MergeNodeModel} from './MergeNodeModel';
import {DiagramEngine, PortModelAlignment, PortWidget} from '@projectstorm/react-diagrams';
import {SparkyELTPortModel} from "../../port/SparkyELTPortModel";
import { S } from '../../port/SimplePortWidget';
import {NodeTypeEnum} from "../NodeTypeEnum";
import DefaultNodeDecoration, {getPrimaryColorByNodeType} from "../DefaultNodeDecoration";
import MergeNodeSettings from "./settings/MergeNodeSettings";
import {useStreamContext} from "../../../../contexts/StreamContext";
import {SparkyNodeStatusInfo} from "../SparkyBasicNodeModel";

export interface MergeNodeWidgetProps {
    node: MergeNodeModel;
    engine: DiagramEngine;
    width: number;
    height: number;
}


const MergeNodeWidget: React.FC<MergeNodeWidgetProps> = (props: MergeNodeWidgetProps) => {

    const {selectedNodeIds, selectNodeIds} = useStreamContext();
    
    const [modalOpen, setModalOpen] = React.useState<boolean>(false);
    const [comment, setComment] = React.useState<string>("");
    const [settingsSummary, setSettingsSummary] = React.useState<string>("🠔 🗡");
    const [highlighted, setHighlighted] = React.useState<boolean>(false);

    const handleOpen = () => {
        setModalOpen(true);
    };

    const handleClose = () => {
        setModalOpen(false);
    };

    const handleOnNodeClick = (event: React.MouseEvent<HTMLDivElement>) => {
        event.stopPropagation(); // Stop propagation to allow dragging
        if (event.shiftKey) { // multi selection
            if (selectedNodeIds.indexOf(props.node.getID()) > -1) {
                // remove from selected elements
                selectNodeIds(selectedNodeIds.filter(item => item !== props.node.getID()));
            } else {
                selectNodeIds([...selectedNodeIds, props.node.getID()])
            }
        } else { // single selection
            // selectNodeIds([props.node.getID()])
            if (selectedNodeIds.indexOf(props.node.getID()) > -1) {
                // selectNodeIds([])
            } else {
                selectNodeIds([props.node.getID()])
            }
            console.log("Shift not pressed " + props.node.getID())
        }
    }

    const [numLinesOut, setNumLinesOut] = React.useState<number | undefined>(undefined);
    React.useEffect(() => {
        const listener = props.node.registerListener({
            eventDidFire: (event: any) => {
                if (event.function === 'statusInfoUpdated') {
                    const info = event.statusInfo as SparkyNodeStatusInfo
                    if (info.count >= 0) {
                        setNumLinesOut(info.count)
                    }
                }
            },
        });
        return () => { listener.deregister(); };
    }, [props.node]);

    React.useEffect(() => {
        if (props.node.isSelected()) {
            setHighlighted(true)
        } else {
            setHighlighted(false)
        }
        // eslint-disable-next-line
    }, [props.node.isSelected()])

    return (
        <div
            className={'diamond-node'}
            style={{
                position: 'relative',
                width: props.width,
                height: props.height
            }}
            onDoubleClick={handleOpen}
            onClick={handleOnNodeClick}
        >
            <svg width="303" height="179" xmlns="http://www.w3.org/2000/svg"
                 xmlnsXlink="http://www.w3.org/1999/xlink" overflow="hidden">
                <defs>
                    <filter id="fx0-nodeMerge" x="-10%" y="-10%" width="120%" height="120%" filterUnits="userSpaceOnUse"
                            primitiveUnits="userSpaceOnUse">
                        <feComponentTransfer colorInterpolationFilters="sRGB">
                            <feFuncR type="discrete" tableValues="0 0"/>
                            <feFuncG type="discrete" tableValues="0 0"/>
                            <feFuncB type="discrete" tableValues="0 0"/>
                            <feFuncA type="linear" slope="0.4" intercept="0"/>
                        </feComponentTransfer>
                        <feGaussianBlur stdDeviation="1.77778 1.77778"/>
                    </filter>
                    <clipPath id="clip1-nodeMerge">
                        <rect x="685" y="494" width="303" height="179"/>
                    </clipPath>
                    <clipPath id="clip2-nodeMerge">
                        <path
                            d="M735.569 502 735.569 502.178 728.202 502.917C707.541 507.124 692 525.315 692 547.119 692 559.578 697.075 570.858 705.28 579.023L707.688 581 705.28 582.977C697.075 591.142 692 602.422 692 614.881 692 636.685 707.541 654.876 728.202 659.083L735.569 659.822 735.569 660 737.338 660 737.339 660 737.34 660 949.538 660C964.152 660 976 648.21 976 633.666L976 528.334C976 513.79 964.152 502 949.538 502L737.339 502ZM685 494 988 494 988 673 685 673Z"
                            fillRule="evenodd" clipRule="evenodd"/>
                    </clipPath>
                </defs>
                <g clipPath="url(#clip1-nodeMerge)" transform="translate(-685 -494)">
                    <g clipPath="url(#clip2-nodeMerge)">
                        <g filter="url(#fx0-nodeMerge)" transform="translate(684 494)">
                            <g>
                                <path
                                    d="M54.3977 10.8284 56.1678 10.8284 268.366 10.8284C282.981 10.8284 294.828 22.6185 294.828 37.1622L294.828 142.495C294.828 157.038 282.981 168.828 268.366 168.828L56.1688 168.828 56.1678 168.828 56.1667 168.828 54.3977 168.828 54.3977 168.651 47.0303 167.912C26.3699 163.705 10.8284 145.513 10.8284 123.709 10.8284 111.25 15.9032 99.9705 24.108 91.8056L26.5161 89.8285 24.108 87.8513C15.9032 79.6864 10.8284 68.4066 10.8284 55.9474 10.8284 34.1437 26.3699 15.9523 47.0303 11.7451L54.3977 11.006Z"
                                    stroke={highlighted ? "#333333" : "#FFFFFF"} strokeWidth="8" strokeMiterlimit="8" fill={getPrimaryColorByNodeType(NodeTypeEnum.NODE_MERGE)}
                                    fillRule="evenodd"/>
                            </g>
                        </g>
                    </g>
                    <path
                        d="M735.569 502 737.339 502 949.538 502C964.152 502 976 513.79 976 528.334L976 633.666C976 648.21 964.152 660 949.538 660L737.34 660 737.339 660 737.338 660 735.569 660 735.569 659.822 728.202 659.083C707.541 654.876 692 636.685 692 614.881 692 602.422 697.075 591.142 705.28 582.977L707.688 581 705.28 579.023C697.075 570.858 692 559.578 692 547.119 692 525.315 707.541 507.124 728.202 502.917L735.569 502.178Z"
                        stroke={highlighted ? "#333333" : "#FFFFFF"} strokeWidth="8" strokeMiterlimit="8" fill={getPrimaryColorByNodeType(NodeTypeEnum.NODE_MERGE)} fillRule="evenodd"/>
                    <path
                        d="M692 615C692 590.147 712.371 570 737.5 570 762.629 570 783 590.147 783 615 783 639.853 762.629 660 737.5 660 712.371 660 692 639.853 692 615Z"
                        stroke={highlighted ? "#333333" : "#FFFFFF"} strokeWidth="8" strokeMiterlimit="8" fill={getPrimaryColorByNodeType(NodeTypeEnum.NODE_MERGE)} fillRule="evenodd"/>
                    <path
                        d="M692 544.5C692 519.371 712.147 499 737 499 761.853 499 782 519.371 782 544.5 782 569.629 761.853 590 737 590 712.147 590 692 569.629 692 544.5Z"
                        stroke={highlighted ? "#333333" : "#FFFFFF"} strokeWidth="8" strokeMiterlimit="8" fill={getPrimaryColorByNodeType(NodeTypeEnum.NODE_MERGE)} fillRule="evenodd"/>
                </g>
            </svg>

            <DefaultNodeDecoration
                nodeType={NodeTypeEnum.NODE_MERGE}
                comment={comment}
                textOnNode={settingsSummary}
                onDoubleClick={handleOpen}
                numLinesOut={numLinesOut}
            />


            <PortWidget
                style={{
                    top: props.height / 3 - 22,
                    left: - 25,
                    position: 'absolute'
                }}
                port={props.node.getPort('inputTop') || new SparkyELTPortModel(PortModelAlignment.LEFT, 'inputTop')}
                engine={props.engine}
            >
                <S.Port/>
            </PortWidget>
            <PortWidget
                style={{
                    top: 2 * props.height / 3 - 22,
                    left: - 25,
                    position: 'absolute'
                }}
                port={props.node.getPort('inputBottom') || new SparkyELTPortModel(PortModelAlignment.LEFT, 'inputBottom')}
                engine={props.engine}
            >
                <S.Port/>
            </PortWidget>

            <PortWidget
                style={{
                    top: props.height / 2 - 22,
                    left: props.width - 14,
                    position: 'absolute'
                }}
                port={props.node.getPort('output') || new SparkyELTPortModel(PortModelAlignment.RIGHT, 'output')}
                engine={props.engine}
            >
                <S.Port/>
            </PortWidget>

            <MergeNodeSettings open={modalOpen}
                                    handleClose={handleClose}
                                    nodeModel={props.node}
                                    comment={comment}
                                    setComment={setComment}
                                    setSettingsSummary={setSettingsSummary}
            />

        </div>
    );
}

export default MergeNodeWidget;