import React, {useState} from "react";
import {
    Dialog,
    DialogActions,
    DialogContent,
    Button,
    Grid,
    FormControl,
    TextField,
    MenuItem,
    Typography,
    IconButton,
} from "@mui/material";
import {ReplaceValsNodeModel, SparkyReplaceCondition} from "../ReplaceValsNodeModel";
import DialogHeader from "../../../../../shared/components/dialogs/DialogHeader";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
// XLSX importieren
import * as XLSX from "xlsx";

interface ReplaceValsNodeSettingsProps {
    open: boolean;
    handleClose: () => void;
    nodeModel: ReplaceValsNodeModel;
    comment: string;
    setComment: React.Dispatch<React.SetStateAction<string>>;
    setSettingsSummary: React.Dispatch<React.SetStateAction<string>>;
}


const ReplaceValsNodeSettings: React.FC<ReplaceValsNodeSettingsProps> = (props: ReplaceValsNodeSettingsProps) => {

    const [rows, setRows] = useState<SparkyReplaceCondition[]>([]);

    const onClose = () => {
        props.handleClose && props.handleClose();
    };

    const onApply = () => {
        
        props.nodeModel.setReplaceConditions(rows)
        // --

        props.nodeModel.setOutputColumns(props.nodeModel.calculateOutputCols())
        props.nodeModel.setComment(props.comment)
        props.nodeModel.setSettingsSummary(props.nodeModel.calcSettingsSummary())
        props.setSettingsSummary(props.nodeModel.getSettingsSummary())
        
        onClose();
    };
    
    // used to re-load the filter-settings after the stream was reconstructed from file
    React.useEffect(() => {
        props.setComment(props.nodeModel.comment)
        // eslint-disable-next-line
    }, [props.nodeModel.comment])
    React.useEffect(() => {
        setRows(props.nodeModel.replaceConditions)
        // eslint-disable-next-line
    }, [props.nodeModel.replaceConditions])

    const handleRemoveRow = (index: number) => {
        const updatedRows = rows.filter((_, i) => i !== index);
        setRows(updatedRows);
    };

    const handleRowChange = (
        index: number,
        field: keyof SparkyReplaceCondition,
        value: string | boolean
    ) => {
        const updatedRows = [...rows];
        updatedRows[index][field] = value as never;
        setRows(updatedRows);
    };

    const toggleMode = (index: number) => {
        const updatedRows = [...rows];
        updatedRows[index].isRegex = !updatedRows[index].isRegex;
        setRows(updatedRows);
    };

    const handleAddRow = () => {
        setRows([
            ...rows,
            {inputColumn: "", fromValue: "", toValue: "", isRegex: false},
        ]);
    };

    // Hier erfolgt das Einlesen der Excel-Datei und das Auslesen der Regeln.
    const handleFileUpload = async (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        const file = event.target.files?.[0];
        if (!file) return;

        const data = await file.arrayBuffer();
        const workbook = XLSX.read(data, {type: "array"});
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];

        const jsonData = XLSX.utils.sheet_to_json<string[]>(sheet, {header: 1});

        const importedRows: SparkyReplaceCondition[] = [];
        let currentColumnName = "";

        for (let i = 0; i < jsonData.length; i++) {
            const row = jsonData[i];

            // Leere Zeilen überspringen
            if (row.length === 0 || !row[0]) {
                continue;
            }

            // Wenn nur eine Zelle vorhanden ist, könnte das ein Spaltenname sein
            if (
                row.length === 1 &&
                typeof row[0] === "string" &&
                !row[0].startsWith("<replace>")
            ) {
                // Dies ist der Spaltenname
                currentColumnName = row[0];
                continue;
            }

            // Jetzt sind wir in den Zeilen, in denen die Ersetzungsregeln stehen
            // Prüfen, ob Regex oder einfaches Replace
            if (row[0].toString().startsWith("<replace>")) {
                // Regex Fall
                // fromValue = Pattern nach "<replace>"
                const pattern = row[0].toString().replace("<replace>", "");
                const replacement = row[1] ? row[1].toString() : "";
                importedRows.push({
                    inputColumn: currentColumnName,
                    fromValue: pattern,
                    toValue: replacement,
                    isRegex: true,
                });
            } else {
                // Normaler Replace-Fall
                const fromVal = row[0] ? row[0].toString() : "";
                const toVal = row[1] ? row[1].toString() : "";
                importedRows.push({
                    inputColumn: currentColumnName,
                    fromValue: fromVal,
                    toValue: toVal,
                    isRegex: false,
                });
            }
        }

        setRows((prev) => [...prev, ...importedRows]);
    };

    return (
        <Dialog
            open={props.open}
            onClose={onClose}
            maxWidth="lg"
            scroll="paper"
            PaperProps={{sx: {backgroundColor: "white"}}}
            disableEnforceFocus
            fullWidth
        >
            <DialogHeader headline={"Replace Values Node Settings"} closeDialog={onClose}/>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        {/* File Upload */}
                        <Button variant="outlined" component="label">
                            Import From Excel
                            <input
                                type="file"
                                hidden
                                onChange={handleFileUpload}
                                accept=".xls,.xlsx"
                            />
                        </Button>
                    </Grid>

                    {rows.map((row, index) => (
                        <Grid item xs={12} key={index}>
                            <Grid container spacing={2} alignItems="center">
                                {/* Dropdown für Input Spalten */}
                                <Grid item xs={4}>
                                    <FormControl fullWidth>
                                        <TextField
                                            select
                                            label="Input Column"
                                            value={row.inputColumn}
                                            onChange={(e) =>
                                                handleRowChange(index, "inputColumn", e.target.value)
                                            }
                                        >
                                            {props.nodeModel.inputColumns.map((col, idx) => (
                                                <MenuItem key={idx} value={col}>
                                                    {col}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={3}>
                                    <TextField
                                        fullWidth
                                        label={row.isRegex ? "RegEx Pattern" : "From Value"}
                                        value={row.fromValue}
                                        onChange={(e) =>
                                            handleRowChange(index, "fromValue", e.target.value)
                                        }
                                    />
                                </Grid>

                                <Grid item xs={1}>
                                    <IconButton
                                        onClick={() => toggleMode(index)}
                                        color={row.isRegex ? "primary" : "default"}
                                    >
                                        <SwapHorizIcon/>
                                    </IconButton>
                                    <Typography align="center" variant="caption">
                                        {row.isRegex ? "RegEx" : "Replace"}
                                    </Typography>
                                </Grid>

                                <Grid item xs={3}>
                                    <TextField
                                        fullWidth
                                        label={row.isRegex ? "RegEx Replacement" : "To Value"}
                                        value={row.toValue}
                                        onChange={(e) =>
                                            handleRowChange(index, "toValue", e.target.value)
                                        }
                                    />
                                </Grid>

                                <Grid item xs={1}>
                                    <Button
                                        onClick={() => handleRemoveRow(index)}
                                        color="error"
                                        size="small"
                                    >
                                        Remove
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    ))}

                    {/* Add new row button */}
                    <Grid item xs={12}>
                        <Button onClick={handleAddRow} variant="outlined">
                            Add Row
                        </Button>
                    </Grid>

                    {/* Comment input */}
                    <Grid item xs={12} sx={{marginTop: '35px'}}>
                        <hr />
                    </Grid>
                    <Grid item xs={12} sx={{marginTop: '15px'}}>
                        <FormControl variant="outlined" fullWidth>
                            <TextField
                                label="Node Comment"
                                value={props.comment}
                                onChange={(e) => props.setComment(e.target.value)}
                                multiline
                                rows={2}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={onApply}>Apply</Button>
            </DialogActions>
        </Dialog>
    );
};

export default ReplaceValsNodeSettings;
