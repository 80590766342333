import * as React from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { Fab, Grid, IconButton, Paper, TextField, Tooltip, Typography } from "@mui/material";
import { Add, Delete } from "@mui/icons-material";
import { useDataSourceClient } from '../../../clients/DataSourceClient';
import { BaseDataSourceResponse } from '../../../clients/model/DataSource';
import { useCallback, useEffect, useRef, useState } from 'react';
import { CreateDataSourceModal } from './components/CreateDataSourceModal';

export interface IDatasourcesConfigProps {
}

const DatasourcesConfig: React.FunctionComponent<IDatasourcesConfigProps> = (props: IDatasourcesConfigProps) => {
    const [datasources, setDatasources] = React.useState<BaseDataSourceResponse[]>([]);
    const [filteredDatasources, setFilteredDatasources] = React.useState<BaseDataSourceResponse[]>([]);
    const dataSourceClient = useDataSourceClient();
    const [createModalOpen, setCreateModalOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const intervalRef = useRef<NodeJS.Timeout>();

    const loadDatasources = useCallback(async () => {
        try {
            const response = await dataSourceClient.getAllDataSources();
            setDatasources(response);
            const filtered = response.filter(datasource => 
                datasource.name.toLowerCase().includes(searchTerm.toLowerCase())
            );
            setFilteredDatasources(filtered);
        } catch (error) {
            console.error('Failed to load datasources:', error);
        }
    }, [dataSourceClient, searchTerm]);
    
    useEffect(() => {
        loadDatasources();
        intervalRef.current = setInterval(loadDatasources, 30000);
        return () => {
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
            }
        };
    }, [loadDatasources]);

    const handleDelete = async (datasourceId: string) => {
        try {
            await dataSourceClient.deleteDataSource(datasourceId);
            await loadDatasources();
        } catch (error) {
            console.error('Failed to delete datasource:', error);
        }
    };

    const columns: GridColDef[] = [
        { field: 'id', headerName: 'ID', width: 220 },
        { field: 'name', headerName: 'Name', width: 150 },
        { field: 'type', headerName: 'Type', width: 120 },
        {
            field: 'actions',
            headerName: 'Actions',
            width: 120,
            sortable: false,
            renderCell: (params) => (
                <Tooltip title="Delete Datasource">
                    <IconButton
                        onClick={() => handleDelete(params.row.id)}
                        color="error"
                    >
                        <Delete />
                    </IconButton>
                </Tooltip>
            ),
        }
    ];

    const handleSearch = (newSearchTerm: string) => {
        setSearchTerm(newSearchTerm);
        const filtered = datasources.filter(datasource => 
            datasource.name.toLowerCase().includes(newSearchTerm.toLowerCase())
        );
        setFilteredDatasources(filtered);
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography variant="h3">
                    Datasource Configuration
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Paper sx={{ padding: "1em" }}>
                    <Grid item xs={12} container spacing={5}>
                        <Grid item xs>
                            <TextField
                                label="Search Datasources"
                                variant="outlined"
                                fullWidth
                                sx={{ marginRight: "1em" }}
                                onChange={(e) => handleSearch(e.target.value)}
                            />
                        </Grid>
                        <Grid item>
                            <Tooltip title="Add Datasource">
                                <Fab
                                    color="primary"
                                    onClick={() => setCreateModalOpen(true)}
                                >
                                    <Add/>
                                </Fab>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
            <Grid item xs={12}>
                <Paper sx={{
                    height: "60vh",
                    padding: "1em",
                    display: "flex",
                    flexDirection: "column",
                }}>
                    <DataGrid
                        rows={filteredDatasources}
                        columns={columns}
                        disableColumnFilter
                        disableColumnSorting
                        autoPageSize
                    />
                </Paper>
            </Grid>
            <CreateDataSourceModal
                open={createModalOpen}
                onClose={() => setCreateModalOpen(false)}
                onSubmit={loadDatasources}
            />
        </Grid>
    );
}

export default DatasourcesConfig;