import { DefaultLinkFactory } from '@projectstorm/react-diagrams';
import { CustomLinkModel } from './CustomLinkModel';
// import { CustomLinkWidget } from './CustomLinkWidget'; // Optional: Custom widget for rendering the link

export class CustomLinkFactory extends DefaultLinkFactory {
    constructor() {
        super('custom-link'); // Use the same type as defined in your CustomLinkModel
    }

    // Override to generate your custom link model
    generateModel(): CustomLinkModel {
        return new CustomLinkModel();
    }

    // Optional: Override to render your custom link widget
    // generateReactWidget(event): JSX.Element {
    //     return <CustomLinkWidget link={event.model} />;
    // }
}
