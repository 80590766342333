import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

// https://locize.com/blog/react-i18next/

i18n
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        debug: true,
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        resources: {
            de: {
                translation: {
                    fileUpload: {
                        uploadFiles: "Dateien hochladen",
                        uploadingFiles: "Dateien hochladen...",
                        filesStillUploading: "Dateien werden noch geladen, bitte warten.",
                        submit: "Bestätigen"
                    },
                    dragAndDrop: {
                        chooseFile: "Dateien auswählen oder hier rein ziehen!"
                    },
                    common: {
                        language: "Sprache",
                        somethingWentWrong: 'Hups, da ist etwas schief gelaufen!',
                        entriesPerPage: 'Einträge pro Seite',
                        confirmPhrase: 'Bitte <strong>{{confirmPhrase}}</strong> zum Bestätigen eingeben',
                        confirm: 'Bestätigen',
                        cancel: 'Abbrechen',
                        copiedToClipboard: 'Zur Zwischenablage hinzugefügt!',
                    },
                    homepage: {
                    },
                    userMenu: {
                        login: "Einloggen",
                        profile: "Profil",
                        settings: "Einstellungen",
                        logout: "Ausloggen",
                    },
                    dashboard: {
                        welcome: "Willkommen",
                        demo: "Zur Demo",
                        myStreams: "Streams",
                        myData: "Daten",
                        mySchedule: "Schedules",
                        newStream: "Neuer Stream",
                        mySecrets: "Secrets",
                        configuration: "Konfiguration",
                    },
                    configuration: {
                        cluster: "Cluster",
                        datasources: "Datenquellen"
                    },
                    login: {
                        login: 'Einloggen',
                        signUp: 'Registrieren',
                        username: 'Nutzername',
                        password: 'Passwort',
                        passwordRepeat: 'Passwort (wdh.)',
                        mail: 'Email',
                        navToLogin: 'Einloggen',
                        navToSignUp: 'Registrieren',
                        usernameOrEmail: 'Nutzername oder Email',
                        forgotPassword: 'Passwort vergessen?',
                        resetPassword: 'Passwort zurücksetzen',
                        reset: 'Zurücksetzen',
                        passwordResetted: 'Passwort zurückgesetzt',
                        thanksWeSentResetMail: 'Vielen Dank! Wir haben Ihnen eine Email mit weiteren Anleitungen gesendet.',
                        passwordSuccessfullyResettedLoginAgain: 'Ihr Passwort wurde erfolgreich zurückgesetzt. Sie können sich nun mit Ihrem neuen Passwort einloggen.',
                        helloPleaseChooseNewPassword: 'Hey, {{username}}! Bitte legen Sie Ihr neues Passwort fest!',
                        newPassword: 'Neues Passwort',
                        newPasswordRepeat: 'Neues Passwort (wdh)',
                        resetLinkNotValid: 'Leider ist Ihr Link ungültig, oder abgelaufen. Bitte fragen Sie erneut die Zurücksetzung Ihres Passworts an.',
                        or: 'Oder',
                        userNotFound: 'Benutzer nicht gefunden - bitte',
                        userAlreadyTaken: 'Benutzer bereits vergeben - bitte',
                    },
                    validations: {
                        usernameMinChar: 'Nutzername muss mind. 3 Zeichen lang sein',
                        chatbotNameMinChar: 'Der Chatbot Name muss mind. 6 Zeichen lang sein',
                        enterValidMail: 'Email ist nicht valid',
                        passwordMinChar: 'Passwort muss mind. 8 Zeichen lang sein',
                        passwordContainDigit: 'Passwort muss eine Zahl enthalten',
                        passwordContainUppercase: 'Passwort muss einen Großbuchstaben enthalten',
                        passwordContainLowercase: 'Passwort muss einen Kleinbuchstaben enthalten',
                        passwordSame: 'Die Passwörter müssen übereinstimmen',

                    },
                    profile: {
                        updateProfileButton: 'Profil Updaten',
                        noCustomer: 'Keinen Kunde angelegt',
                        pricesButton: 'Übersicht der Produkte',
                        createCustomer: 'Profil aktualisieren',
                    },
                }
            },
            en: {
                translation: {
                    fileUpload: {
                        uploadFiles: "Upload your files!",
                        uploadingFiles: "Uploading files...",
                        filesStillUploading: "Files are still uploading, please wait.",
                        submit: "Submit"
                    },
                    dragAndDrop: {
                        chooseFile: "Choose files or drop here!"
                    },
                    common: {
                        language: "Language",
                        somethingWentWrong: 'Whoops, something went wrong!',
                        entriesPerPage: 'Entries per page',
                        confirmPhrase: 'Please type <strong>{{confirmPhrase}}</strong> to confirm.',
                        confirm: 'Confirm',
                        cancel: 'Cancel',
                        copiedToClipboard: 'Copied to clipboard!'
                    },
                    homepage: {
                    },
                    dashboard: {
                        welcome: "Welcome",
                        demo: "To Demo",
                        myStreams: "Streams",
                        myData: "Data",
                        mySchedule: "Schedules",
                        newStream: "New Stream",
                        mySecrets: "Secrets",
                        configuration: "Configuration",
                    },
                    configuration: {
                        cluster: "Cluster",
                        datasources: "Datasources"
                    },
                    userMenu: {
                        login: "Login",
                        profile: "Profile",
                        settings: "Settings",
                        logout: "Logout",
                    },
                    login: {
                        login: 'Login',
                        signUp: 'Sign up',
                        username: 'Username',
                        password: 'Password',
                        passwordRepeat: 'Password (repeat)',
                        mail: 'Email',
                        navToLogin: 'Login',
                        navToSignUp: 'Sign up',
                        usernameOrEmail: 'Username or email',
                        forgotPassword: 'Forgot your password?',
                        resetPassword: 'Reset password',
                        reset: 'Reset',
                        passwordResetted: 'Pasword resetted',
                        thanksWeSentResetMail: 'Thanks! We\'ve sent an email with further instructions.',
                        passwordSuccessfullyResettedLoginAgain: 'Your password was resetted successfully. You can proceed to login now.',
                        helloPleaseChooseNewPassword: 'Hey, {{username}}! Please choose your new password!',
                        newPassword: 'New password',
                        newPasswordRepeat: 'New password (repeat)',
                        resetLinkNotValid: 'Unfortunately, your reset link is invalid or expired. Please try to reset the password again.',
                        or: 'Or',
                        userNotFound: 'User not found - please',
                        userAlreadyTaken: 'User already registered - please',
                    },
                    validations: {
                        usernameMinChar: 'Username must have at least 3 characters',
                        chatbotNameMinChar: 'Chatbot name must have at least 6 characters',
                        enterValidMail: 'Please enter a valid email',
                        passwordMinChar: 'password must have at least 8 characters',
                        passwordContainDigit: 'password must contain a digit',
                        passwordContainUppercase: 'password must contain an uppercase letter',
                        passwordContainLowercase: 'password must contain a lowercase letter',
                        passwordSame: 'passwords must be the same',

                    },
                    profile: {
                        updateProfileButton: 'Update Profile',
                        noCustomer: 'No customer created',
                        pricesButton: 'Overview of products',
                        createCustomer: 'Update profile',
                    },
                }
            }
        }
    });

export default i18n;