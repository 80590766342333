import {
    CircularProgress,
    Fab,
    List,
    ListItem,
    ListItemText,
    Paper,
    TextField,
    Tooltip,
    Typography
} from "@mui/material";
import * as React from "react";
import {ChangeEvent} from "react";
import ClusterView from "../cluster/ClusterView";
import {ClusterContextProvider} from "../../contexts/ClusterContext";
import StartStream from "./components/StartStream";
import InputIcon from "@mui/icons-material/Input";
import {CiFilter} from "react-icons/ci";
import {MdFindReplace, MdJoinInner, MdOutlineCalculate, MdOutlineFolderSpecial,} from "react-icons/md";
import {CgArrowsMergeAltV, CgRename} from "react-icons/cg";
import OutputIcon from "@mui/icons-material/Output";

import {HiOutlineUserGroup} from "react-icons/hi";
import {RiDeleteColumn} from "react-icons/ri";
import {TbReorder} from "react-icons/tb";
import {BsSortDown} from "react-icons/bs";

import createEngine, {
    CanvasWidget,
    DefaultDiagramState,
    DiagramEngine,
    DiagramModel,
    NodeModel,
    PortModelAlignment,
} from "@projectstorm/react-diagrams";
import {ReadNodeFactory} from "../../components/diagram/nodes/read/ReadNodeFactory";
import {GroupByNodeFactory} from "../../components/diagram/nodes/groupBy/GroupByNodeFactory";
import {RemoveColNodeFactory} from "../../components/diagram/nodes/removeCols/RemoveColNodeFactory";
import {FilterNodeFactory} from "../../components/diagram/nodes/filter/FilterNodeFactory";
import {AppendNodeFactory} from "../../components/diagram/nodes/append/AppendNodeFactory";
import {MergeNodeFactory} from "../../components/diagram/nodes/merge/MergeNodeFactory";
import {SortNodeFactory} from "../../components/diagram/nodes/sort/SortNodeFactory";
import {MakeUniqueNodeFactory} from "../../components/diagram/nodes/makeUnique/MakeUniqueNodeFactory";
import {RenameColsNodeFactory} from "../../components/diagram/nodes/renameCols/RenameColsNodeFactory";
import {ReorderColsNodeFactory} from "../../components/diagram/nodes/reorderCols/ReorderColsNodeFactory";
import {ReplaceValsNodeFactory} from "../../components/diagram/nodes/replaceValues/ReplaceValsNodeFactory";
import {WriteNodeFactory} from "../../components/diagram/nodes/write/WriteNodeFactory";
import {CalculateNodeFactory} from "../../components/diagram/nodes/calculate/CalculateNodeFactory";
import NodeToolboxSpeedDial, {ToolboxNode} from "../../shared/components/streamComponents/NodeToolboxSpeedDial";
import {StreamContextProvider} from "../../contexts/StreamContext";
import StreamCanvas from "../../shared/components/streamComponents/StreamCanvas";
import {LuDownloadCloud, LuSave} from "react-icons/lu";
import JSZip from "jszip";
import {SimplePortFactory} from "../../components/diagram/port/SimplePortFactory";
import {SparkyELTPortModel} from "../../components/diagram/port/SparkyELTPortModel";
import {useStreamApiClient} from "../../clients/StreamApiClient";
import {ReadNodeModel} from "../../components/diagram/nodes/read/ReadNodeModel";
import GlobalDateSetter from "../../shared/components/streamComponents/GlobalDateSetter";
import {Box} from "@mui/system";
import {NodeTypeEnum} from "../../components/diagram/nodes/NodeTypeEnum";
import {PiFolderOpenBold} from "react-icons/pi";
import {SparkyNodeStatusInfo} from "../../components/diagram/nodes/SparkyBasicNodeModel";
import {CustomLinkFactory} from "../../components/diagram/link/CustomLinkFactory";

const actionsRead: ToolboxNode[] = [
    {icon: <InputIcon/>, name: "Read", nodeType: NodeTypeEnum.NODE_READ}
];

const actionsCreateAgg: ToolboxNode[] = [
    // { icon: <RiInsertColumnLeft />, name: "Fill new Column" },
    {icon: <HiOutlineUserGroup/>, name: "Group by Column(s)", nodeType: NodeTypeEnum.NODE_GROUP_BY},
    {icon: <MdOutlineFolderSpecial/>, name: "Make Unique", nodeType: NodeTypeEnum.NODE_MAKE_UNIQUE},
    {icon: <MdOutlineCalculate/>, name: "Calculate new Column", nodeType: NodeTypeEnum.NODE_CALCULATE},
];

const actionsRemove: ToolboxNode[] = [
    {icon: <CiFilter/>, name: "Filter Rows", nodeType: NodeTypeEnum.NODE_FILTER},
    {icon: <RiDeleteColumn/>, name: "Remove Columns", nodeType: NodeTypeEnum.NODE_REMOVE_COL},
];

const actionsCombine: ToolboxNode[] = [
    {icon: <MdJoinInner/>, name: "Merge", nodeType: NodeTypeEnum.NODE_MERGE},
    {icon: <CgArrowsMergeAltV/>, name: "Append", nodeType: NodeTypeEnum.NODE_APPEND},
];

const actionModify: ToolboxNode[] = [
    {icon: <MdFindReplace/>, name: "Replace Values", nodeType: NodeTypeEnum.NODE_REPLACE_VALUE},
    {icon: <TbReorder/>, name: "Re-Order Columns", nodeType: NodeTypeEnum.NODE_REORDER_COL},
    {icon: <CgRename/>, name: "Rename Cols", nodeType: NodeTypeEnum.NODE_RENAME_COL},
    {icon: <BsSortDown/>, name: "Sort Cols", nodeType: NodeTypeEnum.NODE_SORT},
];

const actionsWrite: ToolboxNode[] = [
    {icon: <OutputIcon/>, name: "Write", nodeType: NodeTypeEnum.NODE_WRITE}
];

interface IDemoProps {
}

// const downloadStringAsFile = (content: string, filename: string, contentType: string = 'text/plain'): void => {
const downloadStringAsFile = async (
    content: string,
    filename: string,
    zipFilename: string,
    contentType: string = "application/json"
): Promise<void> => {
    // Create a new instance of JSZip
    const zip = new JSZip();

    // Add the JSON file to the ZIP
    zip.file(filename, content);

    // Generate the ZIP file asynchronously
    const blob = await zip.generateAsync({type: "blob"});

    // Create a URL for the Blob
    const url = URL.createObjectURL(blob);

    // Create an anchor element and set its href attribute to the Blob URL
    const a = document.createElement("a");
    a.href = url;
    a.download = zipFilename;

    // Append the anchor to the document body and trigger a click event on it
    document.body.appendChild(a);
    a.click();

    // Remove the anchor from the document body and revoke the Blob URL
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
};

const getChildNodeModels = (
    node: any
): {
    childNodeModels: any[];
    childNodesInputPortNames: string[]
} => {
    const childNodeModels: NodeModel[] = [];
    const childNodesInputPortNames: string[] = [];
    // Iterate over all ports of the node
    Object.values(node.getPorts()).forEach((port: any) => {
        // Iterate over all links connected to this port
        Object.values(port.getLinks()).forEach((link: any) => {
            // Check if this port is the source of the link
            const targetPort = link.getTargetPort();
            const tgtPortName = targetPort.getName(); // input, inputTop, inputBottom
            if (targetPort && link.getSourcePort() === port) {
                const targetNode = targetPort.getNode();
                if (targetNode) {
                    childNodeModels.push(targetNode);
                    childNodesInputPortNames.push(tgtPortName);
                }
            }
        });
    });

    return {childNodeModels, childNodesInputPortNames};
};

const propagateColsToChildren = (model: any) => {
    let outputCols: string[]
    if (model instanceof ReadNodeModel) {
        outputCols = model.getOutputColumns();  // read nodes have the output cols calculated initially
    } else {
        outputCols = model.calculateOutputCols();
    }
    // debugger
    // console.log(model)
    // console.log(outputCols)

    const {childNodeModels, childNodesInputPortNames} = getChildNodeModels(model);
    if (childNodeModels.length === 0) {
        return;
    } else {
        childNodeModels.forEach((childModel: any, idx) => {
            if (childNodesInputPortNames[idx] === "input") {
                childModel.setInputColumns(outputCols);
            } else if (childNodesInputPortNames[idx] === "inputTop") {
                childModel.setInputColumnsTop(outputCols);
            } else if (childNodesInputPortNames[idx] === "inputBottom") {
                childModel.setInputColumnsBottom(outputCols);
            } else {
                console.log("Error: " + childNodesInputPortNames[idx])
            }

            propagateColsToChildren(childModel);
        });
    }
};

const findNodeModelById = (nodeId: string, diagramModel: DiagramModel): any => {
    return diagramModel.getNodes().find((nodeModel) => nodeModel.getID() === nodeId);
};


const sparkyNnodeConnectedListener = {
    eventDidFire: (event: any) => {
        if (event.link) {
            event.link.registerListener({
                targetPortChanged: (event2: any) => {
                    // this event will fire once a connection between ports was established.
                    // now fetch the out-columns from source node
                    // and pass it on to targetNode
                    const sourceModel = event2.entity.sourcePort.parent;
                    const targetModel = event2.entity.targetPort.parent;

                    const sourcePortName = event2.entity.sourcePort.getName();
                    const targetPortName = event2.entity.targetPort.getName();

                    let outCols = [];
                    if (sourcePortName === "output") {
                        outCols = sourceModel.getOutputColumns();
                    } else if (sourcePortName === "outputTop") {
                        outCols = sourceModel.getOutputColumnsTop();
                    } else if (sourcePortName === "outputBottom") {
                        outCols = sourceModel.getOutputColumnsBottom();
                    }

                    if (targetPortName === "input") {
                        targetModel.setInputColumns(outCols);
                    } else if (targetPortName === "inputTop") {
                        targetModel.setInputColumnsTop(outCols);
                    } else if (targetPortName === "inputBottom") {
                        targetModel.setInputColumnsBottom(outCols);
                    }
                },
            });
            /*linksUpdated: (event) => {
                          event.link.registerListener({
                              targetPortChanged: (event) => {
                                  console.log('Link Changed');
                                  debugger
                              }
                          })
                      }*/
            console.log(event);
        }
    },
}

const Demo: React.FunctionComponent<IDemoProps> = (props: IDemoProps) => {
    const streamClient = useStreamApiClient();

    const [engine, setEngine] = React.useState<DiagramEngine | undefined>(undefined);
    const [model, setModel] = React.useState<DiagramModel | undefined>(undefined);

    const [streamRunning, setStreamRunning] = React.useState<boolean>(false)
    const [globalDate, setGlobalDate] = React.useState<string>("2024-12-26")
    const [streamName, setStreamName] = React.useState<string>("Demo-Stream")
    const [nodeStatusEvents, setNodeStatusEvents] = React.useState<Record<string, SparkyNodeStatusInfo>>({});

    const eventSourceRef = React.useRef<EventSource | null>(null);

    React.useEffect(() => {
        if (!model || !streamRunning) {
            return;
        }

        // Avoid creating a new connection if one already exists
        if (!eventSourceRef.current) {
            const eventSource = new EventSource('https://int.sparky-etl.de/api/progress/stream');
            eventSourceRef.current = eventSource;

            eventSource.onopen = () => {
                console.log('SSE connection established');
            };

            eventSource.onmessage = (event) => {
                console.log('event:', event.data);

                const tmp = event.data.split(",");
                if (tmp.length < 3) {
                    console.warn('Malformed data:', event.data);
                    return;
                }

                const nodeId = tmp[0].replace("NodeID: ", "").trim();
                const count = tmp[1].replace("Count: ", "").trim();
                const extraInfo = tmp[2].replace("ExtraInfo: ", "").trim();

                const statusInfo: SparkyNodeStatusInfo = {
                    count: Number(count),
                    extraInfo: extraInfo,
                };

                const nodeModel = findNodeModelById(nodeId, model);
                if (nodeModel) {
                    nodeModel.fireEvent({ statusInfo }, 'statusInfoUpdated');
                    setNodeStatusEvents((prev) => ({
                        ...prev,
                        [nodeId]: statusInfo,
                    }));
                } else {
                    console.warn(`Node model not found for ID: ${nodeId}`);
                }
            };

            eventSource.onerror = (error) => {
                console.error('SSE connection error:', error);
                eventSource.close();
                eventSourceRef.current = null; // Reset ref on error
            };
        }

        return () => {
            // Cleanup: Close the connection when the component unmounts or dependencies change
            if (eventSourceRef.current) {
                console.log('Closing SSE connection');
                eventSourceRef.current.close();
                eventSourceRef.current = null;
            }
        };
    }, [model, streamRunning]); // Dependencies that trigger the effect

    
    React.useEffect(() => {
        const engine = createEngine()
        // Don't allow "loose" links (connections not ending on a port)
        const state = engine.getStateMachine().getCurrentState();
        if (state instanceof DefaultDiagramState) {
            state.dragNewLink.config.allowLooseLinks = false;
        }

        // register some other factories as well

        // Register custom port factory
        engine
            .getPortFactories()
            .registerFactory(
                new SimplePortFactory(
                    "diamond",
                    (config) => new SparkyELTPortModel(PortModelAlignment.LEFT, "input")
                )
            );

        // https://github.com/projectstorm/react-diagrams/issues/49
        engine.maxNumberPointsPerLink = 0 // no extra points so link selection is fired straight away
        // engine
        //     .getPortFactories()
        //     .registerFactory(new SimplePortFactory('diamond', (config) => new SparkyELTPortModel(PortModelAlignment.LEFT)));
        engine.getNodeFactories().registerFactory(new ReadNodeFactory());
        engine.getNodeFactories().registerFactory(new GroupByNodeFactory());
        engine.getNodeFactories().registerFactory(new RemoveColNodeFactory());
        engine.getNodeFactories().registerFactory(new FilterNodeFactory());
        engine.getNodeFactories().registerFactory(new AppendNodeFactory());
        engine.getNodeFactories().registerFactory(new MergeNodeFactory());
        engine.getNodeFactories().registerFactory(new SortNodeFactory());
        engine.getNodeFactories().registerFactory(new MakeUniqueNodeFactory());
        engine.getNodeFactories().registerFactory(new RenameColsNodeFactory());
        engine.getNodeFactories().registerFactory(new ReorderColsNodeFactory());
        engine.getNodeFactories().registerFactory(new ReplaceValsNodeFactory());
        engine.getNodeFactories().registerFactory(new WriteNodeFactory());
        engine.getNodeFactories().registerFactory(new CalculateNodeFactory());
        // link factory (Model and Widget)
        engine.getLinkFactories().registerFactory(new CustomLinkFactory());

        const model = new DiagramModel();

        model.setGridSize(25);
        model.setZoomLevel(75);
        model.registerListener(sparkyNnodeConnectedListener);

        setModel(model);
        engine.setModel(model);

        setEngine(engine)
    }, []);

    // uncomment for perfomance demonstration
    // for (let i = 0; i < 1000; i++) {
    //     const node = new DefaultNodeModel({
    //         name: 'Node ' + i,
    //         color: 'rgb(255,192,255)',
    //     });
    //     node.setPosition(Math.random() * 1000, Math.random() * 500);
    //     node.addInPort('In');
    //     node.addOutPort('Out');

    //     model.addAll(node)
    // }

    const handleFileUpload = async (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        const file = event.target.files?.[0];
        if (!file || !engine) {
            return;
        }

        if (
            file.name.toLowerCase().endsWith(".zip") ||
            file.name.toLowerCase().endsWith(".sparkyetl")
        ) {
            try {
                const zip = new JSZip();
                const content = await zip.loadAsync(file);
                const fileNames = Object.keys(content.files);

                // Assume there is only one JSON file in the zip
                const jsonFileName = fileNames.find((name) => name.endsWith(".json"));
                if (jsonFileName) {
                    const jsonFile = content.files[jsonFileName];
                    const jsonString = await jsonFile.async("string");
                    const model2 = new DiagramModel();
                    model2.deserializeModel(JSON.parse(jsonString), engine);
                    model2.registerListener(sparkyNnodeConnectedListener);
                    engine.setModel(model2);
                    setModel(model2);

                    // w8 some time so all attributes (connections) get propagated...
                    await new Promise((resolve) => setTimeout(resolve, 1000));

                    // find start-nodes
                    const startNodes: any[] = [];
                    model2.getNodes().forEach((node: NodeModel) => {
                        let hasIncomingConnection = false;
                        // Iterate through each port of the node
                        Object.values(node.getPorts()).forEach((port) => {
                            // Check all links connected to this port
                            Object.values(port.getLinks()).forEach((link: any) => {
                                // If the port is the target of the link, it's an incoming connection
                                if (link.getTargetPort() === port) {
                                    hasIncomingConnection = true;
                                }
                            });
                        });

                        // If no incoming connection was found, this is a start-node
                        if (!hasIncomingConnection) {
                            startNodes.push(node);
                        }
                    });


                    // propagate cols to each node
                    startNodes.forEach((nodeModel: any) => {
                        if (nodeModel instanceof ReadNodeModel) {
                            nodeModel.calculateOutputCols().then(() => propagateColsToChildren(nodeModel))
                        }
                    });
                } else {
                    console.error("No JSON file found in the ZIP archive.");
                }
            } catch (error) {
                console.error("Error reading ZIP file:", error);
            }
        } else if (file.name.toLowerCase().endsWith(".json")) {
            const reader = new FileReader();
            reader.onload = (e) => {
                if (e.target) {
                    const jsonString = e.target.result;
                    if (jsonString && typeof jsonString === "string") {
                        const model2 = new DiagramModel();
                        model2.deserializeModel(JSON.parse(jsonString), engine);
                        setModel(model2);
                        engine.setModel(model2);
                    }
                }
            };
            reader.readAsText(file);
        } else {
            console.error("Unsupported file type.");
        }
    };

    const handleNewNodeDragStart = (
        event: React.DragEvent<HTMLDivElement>,
        nodeType: NodeTypeEnum
    ) => {
        event.dataTransfer.setData("nodeType", nodeType);
    };

    const onDownloadClicked = (): void => {
        if (!model) {
            return
        }
        const content = JSON.stringify(model.serialize());
        downloadStringAsFile(content, "stream.json", "stream.sparkyETL");
    };

    const onSaveStreamClicked = (): Promise<any> => {
        if (!model) {
            return Promise.reject(new Error("Model is undefined"));
        }
        const streamID = "aa027e1d-867c-4d2b-9237-d16b5fc96754";
        const content = JSON.stringify(model.serialize());
        return streamClient.saveStream(streamID, streamName, globalDate, content);
    };

    const onUploadClicked = (): void => {
        const elem = document.getElementById("file-input");
        if (elem) {
            elem.click();
        }
    };

    return (
        <>
            {/* Stream Name */}
            <Box
                sx={{
                    position: 'absolute', left: "40px", top: "120px", display: 'flex', alignItems: 'center',
                    justifyContent: 'center', width: '280px', backgroundColor: 'rgba(255,255,255, 0.8)', zIndex: '50'
                }}
            >
                <TextField fullWidth label="Stream Name" name="streamName"
                           value={streamName}
                           onChange={(event: ChangeEvent<HTMLInputElement>) => {
                               setStreamName(event.target.value)
                           }}
                />
            </Box>

            <Paper hidden elevation={3}
                   sx={{position: 'absolute', left: "40px", top: "220px", padding: 2, minHeight: 300, zIndex: '50'}}>
                {Object.keys(nodeStatusEvents).length === 0 ? (
                    <div style={{textAlign: 'center', marginTop: '2rem'}}>
                        <CircularProgress/>
                        <Typography variant="body1" sx={{mt: 2}}>
                            Waiting for updates...
                        </Typography>
                    </div>
                ) : (
                    <List>
                        {Object.keys(nodeStatusEvents).map((nodeID, index) => (
                            <ListItem key={index}>
                                <ListItemText primary={nodeID + " " + nodeStatusEvents[nodeID].count}/>
                            </ListItem>
                        ))}
                    </List>
                )}
            </Paper>

            {model && engine && engine.getModel() && (
                <ClusterContextProvider>

                    <StreamContextProvider>
                        <StreamCanvas
                            engine={engine}
                            model={model}
                        >
                            <CanvasWidget className="canvas" engine={engine}
                            />
                        </StreamCanvas>
                    </StreamContextProvider>

                    <ClusterView/>
                    <StartStream saveStreamHandler={onSaveStreamClicked} setStreamRunning={setStreamRunning}/>
                </ClusterContextProvider>
            )}

            <GlobalDateSetter globalDate={globalDate} setGlobalDate={setGlobalDate}/>

            <Tooltip title={"Save Stream"}>
                <Fab
                    disabled={false}
                    size="small"
                    onClick={onSaveStreamClicked}
                    color="default"
                    sx={{position: "absolute", right: "30px", top: "200px"}}
                >
                    <LuSave style={{fontSize: 25}}/>
                </Fab>
            </Tooltip>

            <Tooltip title={"Upload Stream"}>
                <Fab
                    disabled={false}
                    size="small"
                    onClick={onUploadClicked}
                    color="default"
                    sx={{position: "absolute", right: "30px", top: "260px"}}
                >
                    <PiFolderOpenBold style={{fontSize: 25}}/>
                </Fab>
            </Tooltip>

            <Tooltip title={"Download Stream"}>
                <Fab
                    disabled={false}
                    size="small"
                    onClick={onDownloadClicked}
                    color="default"
                    sx={{position: "absolute", right: "30px", top: "320px"}}
                >
                    <LuDownloadCloud style={{fontSize: 25}}/>
                </Fab>
            </Tooltip>


            <input
                type="file"
                id="file-input"
                accept=".json,.sparkyetl,.zip"
                style={{display: "none"}}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    handleFileUpload(event);
                }}
            />

            <NodeToolboxSpeedDial
                toolboxNodes={actionsRead}
                handleNewNodeDragStart={handleNewNodeDragStart}
                bgcolor={"#93BF79"}
                posBottom={16}
                posRight={440}
            />

            <NodeToolboxSpeedDial
                toolboxNodes={actionsCreateAgg}
                handleNewNodeDragStart={handleNewNodeDragStart}
                bgcolor={"#D3CC84"}
                posBottom={16}
                posRight={355}
            />

            <NodeToolboxSpeedDial
                toolboxNodes={actionsRemove}
                handleNewNodeDragStart={handleNewNodeDragStart}
                bgcolor={"#DBB78B"}
                posBottom={16}
                posRight={270}
            />

            <NodeToolboxSpeedDial
                toolboxNodes={actionsCombine}
                handleNewNodeDragStart={handleNewNodeDragStart}
                bgcolor={"#E3A293"}
                posBottom={16}
                posRight={185}
            />

            <NodeToolboxSpeedDial
                toolboxNodes={actionModify}
                handleNewNodeDragStart={handleNewNodeDragStart}
                bgcolor={"#DBC8FC"}
                posBottom={16}
                posRight={100}
            />

            <NodeToolboxSpeedDial
                toolboxNodes={actionsWrite}
                handleNewNodeDragStart={handleNewNodeDragStart}
                bgcolor={"#E3EDFF"}
                posBottom={16}
                posRight={16}
            />
        </>
    );
};

export default Demo;
