import { Badge, Box, Grid, IconButton, MenuItem, Select, Tooltip } from '@mui/material';
import * as React from 'react';
import { useState } from 'react';
import { Article, Error, PlayArrow, Settings, Stop } from '@mui/icons-material';
import { useClusterContext } from '../../contexts/ClusterContext';
import ClusterStatusIcon from './components/ClusterStatusIcon';
import ClusterLogDialog from './components/ClusterLogDialog';
import { ClusterStatus } from '../../clients/model/Cluster';
import ManageClusterModal from '../configuration/cluster/components/ManageClusterModal';

export interface IClusterViewProps {
}

const ClusterView: React.FunctionComponent<IClusterViewProps> = (props: IClusterViewProps) => {
    const clusterCtx = useClusterContext();
    const [logsOpen, setLogsOpen] = useState(false);
    const [manageModalOpen, setManageModalOpen] = useState(false);

    const showStartButton = clusterCtx.selectedCluster && [
        ClusterStatus.TERMINATED,
        ClusterStatus.STOPPED,
        ClusterStatus.PENDING
    ].includes(clusterCtx.selectedCluster.status);

    const showStopButton = clusterCtx.selectedCluster && [
        ClusterStatus.RUNNING,
        ClusterStatus.BUSY,
        ClusterStatus.ERROR,
        ClusterStatus.TERMINATING,
        ClusterStatus.STARTING
    ].includes(clusterCtx.selectedCluster.status);

    return (
        <>
            <Box
                sx={{
                    position: 'absolute',
                    left: "50px",
                    bottom: "20px",
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '30vw',
                }}
            >
                <Grid container sx={{ padding: "0.5em" }}>
                    <Grid item xs={12} container spacing={2} alignItems="center">
                        <Grid item xs={6} md={8}>
                            <Select
                                fullWidth
                                value={clusterCtx.selectedClusterId || "----"}
                                onChange={(e) => clusterCtx.setSelectedCluster(e.target.value)}
                            >
                                {clusterCtx.availableClusters.length > 0 ? 
                                    clusterCtx.availableClusters.map(c => (
                                        <MenuItem key={c.id} value={c.id}>
                                            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                                <ClusterStatusIcon status={c} />
                                                {c.name} 
                                            </div>
                                        </MenuItem>
                                    ))
                                    : <MenuItem value="----"><i>no cluster available</i></MenuItem>
                                }
                            </Select>
                        </Grid>
                        <Grid item xs={6} md={4} container alignItems="center" justifyContent="space-evenly">
                            <Grid item>
                                {clusterCtx.selectedCluster?.type === 'SPARKY' && (
                                    showStartButton ? (
                                        <Tooltip title="Start cluster">
                                            <IconButton 
                                                onClick={() => clusterCtx.startCluster()} 
                                                color="success"
                                                disabled={clusterCtx.selectedCluster.status === ClusterStatus.PENDING}
                                            >
                                                <PlayArrow />
                                            </IconButton>
                                        </Tooltip>
                                    ) : showStopButton ? (
                                        <Tooltip title="Stop cluster">
                                            <IconButton 
                                                onClick={() => clusterCtx.stopCluster()} 
                                                color="error"
                                                disabled={clusterCtx.selectedCluster.status === ClusterStatus.TERMINATING}
                                            >
                                                <Stop />
                                            </IconButton>
                                        </Tooltip>
                                    ) : null
                                )}
                            </Grid>
                            <Grid item>
                                <Tooltip title="View Cluster Logs">
                                    <Badge badgeContent={(clusterCtx.clusterLogsChanged || clusterCtx.sparkLogsChanged) && <Error color='error' />} >
                                        <IconButton
                                            disabled={!clusterCtx.selectedClusterId}
                                            onClick={() => setLogsOpen(true)} 
                                            color="info"
                                        >
                                            <Article />
                                        </IconButton>
                                    </Badge>
                                </Tooltip>
                            </Grid>
                            <Grid item>
                                <Tooltip title="Manage Clusters">
                                    <IconButton
                                        onClick={() => setManageModalOpen(true)}
                                        color="primary"
                                    >
                                        <Settings />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
            <ClusterLogDialog open={logsOpen} onClose={() => setLogsOpen(false)} />
            <ManageClusterModal 
                open={manageModalOpen}
                onClose={() => setManageModalOpen(false)}
            />
        </>
    );
}

export default ClusterView;