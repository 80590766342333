import * as React from 'react';
import {RemoveColNodeModel} from './RemoveColNodeModel';
import {DiagramEngine, PortModelAlignment, PortWidget} from '@projectstorm/react-diagrams';
import {SparkyELTPortModel} from "../../port/SparkyELTPortModel";
import {S} from '../../port/SimplePortWidget';
import DefaultNodeDecoration, {getPrimaryColorByNodeType} from '../DefaultNodeDecoration';
import {NodeTypeEnum} from "../NodeTypeEnum";
import RemoveColsNodeSettings from "./settings/RemoveColsNodeSettings";
import {useStreamContext} from "../../../../contexts/StreamContext";
import {SparkyNodeStatusInfo} from "../SparkyBasicNodeModel";

export interface RemColNodeWidgetProps {
    node: RemoveColNodeModel;
    engine: DiagramEngine;
    width: number;
    height: number;
}

const RemoveColNodeWidget: React.FC<RemColNodeWidgetProps> = (props: RemColNodeWidgetProps) => {

    const {selectedNodeIds, selectNodeIds} = useStreamContext();
    
    const [modalOpen, setModalOpen] = React.useState<boolean>(false);
    const [comment, setComment] = React.useState<string>("");
    const [settingsSummary, setSettingsSummary] = React.useState<string>(props.node.getSettingsSummary());
    const [highlighted, setHighlighted] = React.useState<boolean>(false);

    const handleOpen = () => {
        setModalOpen(true);
    };

    const handleClose = () => {
        setModalOpen(false);
    };

    const handleOnNodeClick = (event: React.MouseEvent<HTMLDivElement>) => {
        event.stopPropagation(); // Stop propagation to allow dragging
        if (event.shiftKey) { // multi selection
            if (selectedNodeIds.indexOf(props.node.getID()) > -1) {
                // remove from selected elements
                selectNodeIds(selectedNodeIds.filter(item => item !== props.node.getID()));
            } else {
                selectNodeIds([...selectedNodeIds, props.node.getID()])
            }
        } else { // single selection
            // selectNodeIds([props.node.getID()])
            if (selectedNodeIds.indexOf(props.node.getID()) > -1) {
                // selectNodeIds([])
            } else {
                selectNodeIds([props.node.getID()])
            }
            console.log("Shift not pressed " + props.node.getID())
        }
    }

    const [numLinesOut, setNumLinesOut] = React.useState<number | undefined>(undefined);
    React.useEffect(() => {
        const listener = props.node.registerListener({
            eventDidFire: (event: any) => {
                if (event.function === 'statusInfoUpdated') {
                    const info = event.statusInfo as SparkyNodeStatusInfo
                    if (info.count >= 0) {
                        setNumLinesOut(info.count)
                    }
                }
            },
        });
        return () => { listener.deregister(); };
    }, [props.node]);

    React.useEffect(() => {
        if (props.node.isSelected()) {
            setHighlighted(true)
        } else {
            setHighlighted(false)
        }
        // eslint-disable-next-line
    }, [props.node.isSelected()])

    return (
        <div
            className={'diamond-node'}
            style={{
                position: 'relative',
                width: props.width,
                height: props.height
            }}
            onDoubleClick={handleOpen}
            onClick={handleOnNodeClick}
        >
            <svg width="304" height="179" xmlns="http://www.w3.org/2000/svg"
                 xmlnsXlink="http://www.w3.org/1999/xlink" overflow="hidden">
                <defs>
                    <filter id="fx0-nodeRemCol" x="-10%" y="-10%" width="120%" height="120%"
                            filterUnits="userSpaceOnUse"
                            primitiveUnits="userSpaceOnUse">
                        <feComponentTransfer colorInterpolationFilters="sRGB">
                            <feFuncR type="discrete" tableValues="0 0"/>
                            <feFuncG type="discrete" tableValues="0 0"/>
                            <feFuncB type="discrete" tableValues="0 0"/>
                            <feFuncA type="linear" slope="0.4" intercept="0"/>
                        </feComponentTransfer>
                        <feGaussianBlur stdDeviation="1.77778 1.77778"/>
                    </filter>
                    <filter id="fx1-nodeRemCol" x="-10%" y="-10%" width="120%" height="120%"
                            filterUnits="userSpaceOnUse"
                            primitiveUnits="userSpaceOnUse">
                        <feComponentTransfer colorInterpolationFilters="sRGB">
                            <feFuncR type="discrete" tableValues="0 0"/>
                            <feFuncG type="discrete" tableValues="0 0"/>
                            <feFuncB type="discrete" tableValues="0 0"/>
                            <feFuncA type="linear" slope="0.4" intercept="0"/>
                        </feComponentTransfer>
                        <feGaussianBlur stdDeviation="1.77778 1.77778"/>
                    </filter>
                    <filter id="fx2-nodeRemCol" x="-10%" y="-10%" width="120%" height="120%"
                            filterUnits="userSpaceOnUse"
                            primitiveUnits="userSpaceOnUse">
                        <feComponentTransfer colorInterpolationFilters="sRGB">
                            <feFuncR type="discrete" tableValues="0 0"/>
                            <feFuncG type="discrete" tableValues="0 0"/>
                            <feFuncB type="discrete" tableValues="0 0"/>
                            <feFuncA type="linear" slope="0.4" intercept="0"/>
                        </feComponentTransfer>
                        <feGaussianBlur stdDeviation="1.77778 1.77778"/>
                    </filter>
                    <filter id="fx3-nodeRemCol" x="-10%" y="-10%" width="120%" height="120%"
                            filterUnits="userSpaceOnUse"
                            primitiveUnits="userSpaceOnUse">
                        <feComponentTransfer colorInterpolationFilters="sRGB">
                            <feFuncR type="discrete" tableValues="0 0"/>
                            <feFuncG type="discrete" tableValues="0 0"/>
                            <feFuncB type="discrete" tableValues="0 0"/>
                            <feFuncA type="linear" slope="0.4" intercept="0"/>
                        </feComponentTransfer>
                        <feGaussianBlur stdDeviation="1.77778 1.77778"/>
                    </filter>
                    <clipPath id="clip4-nodeRemCol">
                        <rect x="64" y="241" width="304" height="179"/>
                    </clipPath>
                    <clipPath id="clip5-nodeRemCol">
                        <path
                            d="M97.5415 248C82.883 248 71 259.865 71 274.5L71 380.5C71 395.135 82.883 407 97.5415 407L268 407 268 248ZM64 241 368 241 368 420 64 420Z"
                            fillRule="evenodd" clipRule="evenodd"/>
                    </clipPath>
                    <clipPath id="clip6-nodeRemCol">
                        <path d="M278 248 278 407 303 407 303 248ZM64 241 368 241 368 420 64 420Z"
                              fillRule="evenodd" clipRule="evenodd"/>
                    </clipPath>
                    <clipPath id="clip7-nodeRemCol">
                        <path
                            d="M314 248 314 407 328.775 407C343.259 407 355 395.135 355 380.5L355 274.5C355 259.865 343.259 248 328.775 248ZM64 241 368 241 368 420 64 420Z"
                            fillRule="evenodd" clipRule="evenodd"/>
                    </clipPath>
                    <clipPath id="clip8-nodeRemCol">
                        <path
                            d="M291 253.5C281.887 253.5 274.5 260.887 274.5 270 274.5 279.113 281.887 286.5 291 286.5 300.113 286.5 307.5 279.113 307.5 270 307.5 260.887 300.113 253.5 291 253.5ZM64 241 368 241 368 420 64 420Z"
                            fillRule="evenodd" clipRule="evenodd"/>
                    </clipPath>
                </defs>
                <g clipPath="url(#clip4-nodeRemCol)" transform="translate(-64 -241)">
                    <g clipPath="url(#clip5-nodeRemCol)">
                        <g filter="url(#fx0-nodeRemCol)" transform="translate(63 240)">
                            <g>
                                <path
                                    d="M37.3699 10.8284 207.828 10.8284 207.828 169.828 37.3699 169.828C22.7114 169.828 10.8284 157.964 10.8284 143.328L10.8284 37.3289C10.8284 22.6931 22.7114 10.8284 37.3699 10.8284Z"
                                    stroke={highlighted ? "#333333" : "#FFFFFF"} strokeWidth="8" strokeMiterlimit="8"
                                    fill={getPrimaryColorByNodeType(NodeTypeEnum.NODE_REMOVE_COL)}
                                    fillRule="evenodd"/>
                            </g>
                        </g>
                    </g>
                    <path
                        d="M97.5415 248 268 248 268 407 97.5415 407C82.883 407 71 395.135 71 380.5L71 274.5C71 259.865 82.883 248 97.5415 248Z"
                        stroke={highlighted ? "#333333" : "#FFFFFF"} strokeWidth="8" strokeMiterlimit="8"
                        fill={getPrimaryColorByNodeType(NodeTypeEnum.NODE_REMOVE_COL)} fillRule="evenodd"/>
                    <g clipPath="url(#clip6-nodeRemCol)">
                        <g filter="url(#fx1-nodeRemCol)" transform="translate(270 240)">
                            <g>
                                <rect x="10.8284" y="10.8284" width="25" height="159"
                                      stroke={highlighted ? "#333333" : "#FFFFFF"}
                                      strokeWidth="8" strokeMiterlimit="8"
                                      fill={getPrimaryColorByNodeType(NodeTypeEnum.NODE_REMOVE_COL)}/>
                            </g>
                        </g>
                    </g>
                    <rect x="278" y="248" width="25" height="159" stroke={highlighted ? "#333333" : "#FFFFFF"}
                          strokeWidth="8"
                          strokeMiterlimit="8" fill={getPrimaryColorByNodeType(NodeTypeEnum.NODE_REMOVE_COL)}/>
                    <g clipPath="url(#clip7-nodeRemCol)">
                        <g filter="url(#fx2-nodeRemCol)" transform="translate(306 240)">
                            <g>
                                <path
                                    d="M41 0 26.2251 0C11.7414 0 0 11.8647 0 26.5005L0 132.5C0 147.135 11.7414 159 26.2251 159L41 159Z"
                                    stroke={highlighted ? "#333333" : "#FFFFFF"} strokeWidth="8" strokeMiterlimit="8"
                                    fill={getPrimaryColorByNodeType(NodeTypeEnum.NODE_REMOVE_COL)}
                                    fillRule="evenodd" transform="matrix(-1 0 0 1 51.8284 10.8284)"/>
                            </g>
                        </g>
                    </g>
                    <path
                        d="M41 0 26.2251 0C11.7414 0 0 11.8647 0 26.5005L0 132.5C0 147.135 11.7414 159 26.2251 159L41 159Z"
                        stroke={highlighted ? "#333333" : "#FFFFFF"} strokeWidth="8" strokeMiterlimit="8"
                        fill={getPrimaryColorByNodeType(NodeTypeEnum.NODE_REMOVE_COL)} fillRule="evenodd"
                        transform="matrix(-1 0 0 1 355 248)"/>
                    <g clipPath="url(#clip8-nodeRemCol)">
                        <g filter="url(#fx3-nodeRemCol)" transform="translate(270 249)">
                            <g>
                                <path
                                    d="M7.32849 23.8285C7.32849 14.7158 14.7158 7.32849 23.8285 7.32849 32.9412 7.32849 40.3285 14.7158 40.3285 23.8285 40.3285 32.9412 32.9412 40.3285 23.8285 40.3285 14.7158 40.3285 7.32849 32.9412 7.32849 23.8285Z"
                                    stroke="#172C51" strokeMiterlimit="8" fill="#FFFFFF" fillRule="evenodd"/>
                            </g>
                        </g>
                    </g>
                    <path
                        d="M274.5 270C274.5 260.887 281.887 253.5 291 253.5 300.113 253.5 307.5 260.887 307.5 270 307.5 279.113 300.113 286.5 291 286.5 281.887 286.5 274.5 279.113 274.5 270Z"
                        stroke="#172C51" strokeWidth="0.333333" strokeMiterlimit="8" fill="#FFFFFF"
                        fillRule="evenodd"/>
                    <path d="M279 270 301.292 270" stroke="#404040" strokeWidth="4" strokeMiterlimit="8"
                          fill="none" fillRule="evenodd"/>
                </g>
            </svg>

            <DefaultNodeDecoration
                nodeType={NodeTypeEnum.NODE_REMOVE_COL}
                comment={comment}
                textOnNode={settingsSummary}
                onDoubleClick={handleOpen}
                numLinesOut={numLinesOut}
            />

            <PortWidget
                style={{
                    top: props.height / 2 - 22,
                    left: -25,
                    position: 'absolute'
                }}
                port={props.node.getPort('input') || new SparkyELTPortModel(PortModelAlignment.LEFT, 'input')}
                engine={props.engine}
            >
                <S.Port/>
            </PortWidget>
            <PortWidget
                style={{
                    top: props.height / 2 - 22,
                    left: props.width - 14,
                    position: 'absolute'
                }}
                port={props.node.getPort('output') || new SparkyELTPortModel(PortModelAlignment.RIGHT, 'output')}
                engine={props.engine}
            >
                <S.Port/>
            </PortWidget>

            <RemoveColsNodeSettings open={modalOpen}
                                    handleClose={handleClose}
                                    nodeModel={props.node}
                                    comment={comment}
                                    setComment={setComment}
                                    setSettingsSummary={setSettingsSummary}
            />
        </div>
    );
}

export default RemoveColNodeWidget;