import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, InputLabel, MenuItem, Select, Switch, TextField, Tooltip, Typography } from "@mui/material";
import { ClusterType } from "../../../../clients/model/Cluster";
import { useState } from "react";

interface CreateClusterModalProps {
    open: boolean;
    onClose: () => void;
    onSubmit: (values: {
        clusterName: string;
        clusterType: ClusterType;
        autoShutdown?: boolean;
        connectionString?: string;
    }) => void;
}

export const CreateClusterModal = ({ open, onClose, onSubmit }: CreateClusterModalProps) => {
    const [values, setValues] = useState({
        clusterName: '',
        clusterType: ClusterType.SPARKY,
        autoShutdown: true,
        connectionString: ''
    });

    const handleSubmit = () => {
        onSubmit(values);
        setValues({
            clusterName: '',
            clusterType: ClusterType.SPARKY,
            autoShutdown: true,
            connectionString: ''
        });
    };

    const isFormComplete = (): boolean => {
        if (!values.clusterName.trim()) return false;
        
        if (values.clusterType === ClusterType.CUSTOM) {
            return !!values.connectionString?.trim();
        }
        
        return true;
    };

    const getTooltipMessage = (): string => {
        if (!values.clusterName.trim()) return "Please enter a cluster name";
        if (values.clusterType === ClusterType.CUSTOM && !values.connectionString?.trim()) {
            return "Please enter a connection string";
        }
        return "";
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Create New Cluster</DialogTitle>
            <DialogContent>
                <FormControl fullWidth margin="dense" sx={{ mt: 2, mb: 2 }}>
                    <InputLabel>Type</InputLabel>
                    <Select
                        value={values.clusterType}
                        label="Type"
                        onChange={(e) => setValues({ ...values, clusterType: e.target.value as ClusterType })}
                    >
                        <MenuItem value={ClusterType.SPARKY}>Sparky</MenuItem>
                        <MenuItem value={ClusterType.CUSTOM}>Custom</MenuItem>
                    </Select>
                </FormControl>

                <TextField
                    margin="dense"
                    label="Cluster Name"
                    fullWidth
                    required
                    value={values.clusterName}
                    onChange={(e) => setValues({ ...values, clusterName: e.target.value })}
                    sx={{ mt: 2, mb: 1 }}
                />

                {values.clusterType === ClusterType.SPARKY ? (
                    <FormControlLabel
                        control={
                            <Switch
                                checked={true}
                                disabled
                                onChange={(e) => setValues({ ...values, autoShutdown: e.target.checked })}
                            />
                        }
                        label={
                            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                Auto Shutdown
                                <Typography variant="caption" sx={{ fontStyle: 'italic', color: 'text.secondary' }}>
                                    (Currently always enabled for performance reasons)
                                </Typography>
                            </div>
                        }
                        sx={{ mt: 2, mb: 1 }}
                    />
                ) : (
                    <TextField
                        margin="dense"
                        label="Connection String"
                        fullWidth
                        required
                        value={values.connectionString}
                        onChange={(e) => setValues({ ...values, connectionString: e.target.value })}
                        sx={{ mt: 2, mb: 1 }}
                    />
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Tooltip 
                    title={getTooltipMessage()} 
                >
                    <span>
                        <Button 
                            onClick={handleSubmit} 
                            variant="contained" 
                            color="primary"
                            disabled={!isFormComplete()}
                        >
                            Create
                        </Button>
                    </span>
                </Tooltip>
            </DialogActions>
        </Dialog>
    );
}; 