import {ReadNodeModel} from "../../../components/diagram/nodes/read/ReadNodeModel";
import {
    DiagramModel,
} from "@projectstorm/react-diagrams";
import * as React from "react";
import {useStreamContext} from "../../../contexts/StreamContext";
import {Paper} from "@mui/material";
import {ReactNode} from "react";
import {GroupByNodeModel} from "../../../components/diagram/nodes/groupBy/GroupByNodeModel";
import {FilterNodeModel} from "../../../components/diagram/nodes/filter/FilterNodeModel";
import {RemoveColNodeModel} from "../../../components/diagram/nodes/removeCols/RemoveColNodeModel";
import {MergeNodeModel} from "../../../components/diagram/nodes/merge/MergeNodeModel";
import {AppendNodeModel} from "../../../components/diagram/nodes/append/AppendNodeModel";
import {ReplaceValsNodeModel} from "../../../components/diagram/nodes/replaceValues/ReplaceValsNodeModel";
import {ReorderColsNodeModel} from "../../../components/diagram/nodes/reorderCols/ReorderColsNodeModel";
import {RenameColsNodeModel} from "../../../components/diagram/nodes/renameCols/RenameColsNodeModel";
import {SortNodeModel} from "../../../components/diagram/nodes/sort/SortNodeModel";
import {MakeUniqueNodeModel} from "../../../components/diagram/nodes/makeUnique/MakeUniqueNodeModel";
import {WriteNodeModel} from "../../../components/diagram/nodes/write/WriteNodeModel";
import {CalculateNodeModel} from "../../../components/diagram/nodes/calculate/CalculateNodeModel";
import {CanvasEngine} from "@projectstorm/react-canvas-core/dist/@types/CanvasEngine";
import {NodeTypeEnum} from "../../../components/diagram/nodes/NodeTypeEnum";
import {MyStreamZoomPanEvent, MyStreamZoomPanListener} from "./MySreamZoomPanListener";

export interface StreamCanvasProps {
    children: ReactNode;
    engine: CanvasEngine;
    model: DiagramModel;
}

const StreamCanvas: React.FC<StreamCanvasProps> = (
    props: StreamCanvasProps
) => {
    const {selectNodeIds} = useStreamContext();

    // Store diagram transform in React state
    const [offset, setOffset] = React.useState({x: props.model.getOffsetX(), y: props.model.getOffsetY()});
    const [zoom, setZoom] = React.useState(props.model.getZoomLevel()); // Zoom is typically 100 = 100%

    // zoom/pan listener to adjust grid in background
    React.useEffect(() => {
        const myListener: MyStreamZoomPanListener = {
            offsetUpdated: (event: MyStreamZoomPanEvent) => {
                if (event.offsetX !== undefined && event.offsetY !== undefined) {
                    setOffset({x: event.offsetX, y: event.offsetY});
                }
            },
            zoomUpdated: (event: MyStreamZoomPanEvent & { zoom: number }) => {
                if (event.zoom !== undefined) {
                    setZoom(event.zoom);
                }
            }
        };
        const handle = props.model.registerListener(myListener);

        // Cleanup on unmount
        return () => {
            handle.deregister();
        };
    }, [props.model]);

    // <editor-fold desc=" Drag&Dorp handlers ">

    const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        const nodeType = event.dataTransfer.getData("nodeType");

        if (canvasRef.current) {
            const canvasRect = canvasRef.current.getBoundingClientRect();
            const zoomFactor = props.engine.getModel().getZoomLevel() / 100;
            const offsetX = props.engine.getModel().getOffsetX();
            const offsetY = props.engine.getModel().getOffsetY();
            const x = (event.clientX - canvasRect.left - offsetX) / zoomFactor;
            const y = (event.clientY - canvasRect.top - offsetY) / zoomFactor;

            let newNode;
            if (nodeType === NodeTypeEnum.NODE_READ) {
                newNode = new ReadNodeModel("", "");
            } else if (nodeType === NodeTypeEnum.NODE_GROUP_BY) {
                newNode = new GroupByNodeModel();
            } else if (nodeType === NodeTypeEnum.NODE_FILTER) {
                newNode = new FilterNodeModel();
            } else if (nodeType === NodeTypeEnum.NODE_REMOVE_COL) {
                newNode = new RemoveColNodeModel();
            } else if (nodeType === NodeTypeEnum.NODE_MERGE) {
                newNode = new MergeNodeModel();
            } else if (nodeType === NodeTypeEnum.NODE_APPEND) {
                newNode = new AppendNodeModel();
            } else if (nodeType === NodeTypeEnum.NODE_REPLACE_VALUE) {
                newNode = new ReplaceValsNodeModel();
            } else if (nodeType === NodeTypeEnum.NODE_REORDER_COL) {
                newNode = new ReorderColsNodeModel();
            } else if (nodeType === NodeTypeEnum.NODE_RENAME_COL) {
                newNode = new RenameColsNodeModel();
            } else if (nodeType === NodeTypeEnum.NODE_SORT) {
                newNode = new SortNodeModel();
            } else if (nodeType === NodeTypeEnum.NODE_MAKE_UNIQUE) {
                newNode = new MakeUniqueNodeModel();
            } else if (nodeType === NodeTypeEnum.NODE_WRITE) {
                newNode = new WriteNodeModel();
            } else if (nodeType === NodeTypeEnum.NODE_CALCULATE) {
                newNode = new CalculateNodeModel();
            }

            if (newNode) {
                newNode.setPosition(x, y);
                props.model.addNode(newNode);
                /*
                        const newModel = props.model.addNode(newNode);
                        newModel.registerListener({
                            selectionChanged: () => { console.log("selectionChanged") },
                            nodesUpdated: () => { console.log("nodesUpdated") },
                            linksUpdated: () => { console.log("linksUpdated") },
                            offsetUpdated: () => { console.log("offsetUpdated") },
                            zoomUpdated: () => { console.log("zoomUpdated") },
                            gridUpdated: () => { console.log("gridUpdated") },
                            entityRemoved: () => { console.log("entityRemoved") },
                        });*/

                newNode.registerListener({
                    /* eventDidFire: (event: any) => {
                                  console.log('element eventDidFire')
                                  console.log(event)
                              }, */
                    selectionChanged: (event: any) => {
                        console.log("element selectionChanged");
                        console.log(event);
                    },
                    // positionChanged
                    // nodesUpdated
                });

                props.engine.repaintCanvas();
            }
        }
    };

    const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
    };
    // </editor-fold>

    // ensure Canvas access its props only once it is loaded fully
    const canvasRef = React.useRef<HTMLDivElement | null>(null);
    React.useEffect(() => {
        if (canvasRef.current) {
            props.engine.getModel().registerListener({
                nodesUpdated: () => {
                    console.log("Nodes updated");
                },
            });
        }
    }, [canvasRef, props.engine]);

    const handleOnCanvasClicked = (
        event: React.MouseEvent<HTMLDivElement>
    ): void => {
        event.stopPropagation();
        console.log("handleOnCanvasClicked");
        // de-select any selected node
        selectNodeIds([]);
    };

    // Use offset + zoom for a dynamic grid background
    const scale = zoom / 100;       // 100 => 1.0, 200 => 2.0, etc.
    const baseCellSize = 50;
    const bgSize = baseCellSize * scale;
    const bgPosX = offset.x;
    const bgPosY = offset.y;

    /*
    const crossSvg = `
      <svg 
        viewBox="0 0 25 25" 
        width="25" 
        height="25" 
        xmlns="http://www.w3.org/2000/svg"
      >
        
        <!-- Vertical rectangle for the plus sign -->
        <rect x="0" y="0" width="1" height="2.5" fill="gray" />
        <rect x="0" y="22.5" width="1" height="2.5" fill="gray" />
        <!-- Horizontal rectangle for the plus sign -->
        <rect x="0" y="0" width="2.5" height="1" fill="gray" />
        <rect x="22.5" y="0" width="2.5" height="1" fill="gray" />
      </svg>
    `;
  
    // 2) Encode that SVG in base64 (so we don’t worry about escaping quotes, etc.)
    const crossBase64 = btoa(crossSvg.trim());
    const crossUrl = `data:image/svg+xml;base64,${crossBase64}`;
  
  
    backgroundImage: `url('${crossUrl}')`,
          backgroundRepeat: 'repeat',
     */
    return (
        <Paper
            ref={canvasRef}
            sx={{
                position: "absolute",
                left: "0px", top: "0px",
                margin: "0px", padding: "0px",
                width: "100%",
                height: "100%",
                ".canvas": {
                    width: "100%",
                    height: "100%",
                },
                backgroundColor: '#f9f9f9',
                backgroundImage: `
                  radial-gradient(
                    circle,
                    #000000 1px,
                    rgba(0, 0, 0, 0) 1px
                  )`,
                backgroundSize: `${bgSize}px ${bgSize}px`,
                backgroundPosition: `${bgPosX}px ${bgPosY}px`
            }}
            onDrop={handleDrop}
            onDragOver={handleDragOver}
            onClick={handleOnCanvasClicked}
        >
            {props.children}
        </Paper>
    );
};

export default StreamCanvas;
